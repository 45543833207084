import * as React from "react";
import { EditNote, Home } from "@mui/icons-material";
import { Grid, Box, IconButton, FormControl, FormLabel, Input, Button, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import Customer from "../../../interfaces/customer";
import { IMaskInput } from "react-imask";
import { debounce } from "@mui/material/utils";
import { cepAutoComplete } from "../../../services/cepService";
import BaseInput from "../../global_components/BaseInput";
import BaseForm from "../../../providers/Errors";

//código para aplicar máscara em Input
interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

const TextMaskAdapter = React.forwardRef<HTMLInputElement, CustomProps>(function TextMaskAdapter(props, ref) {
	const { onChange, ...other } = props;
	return (
		<IMaskInput
			{...other}
			mask="00000-000"
			definitions={{
				"#": /[1-9]/,
			}}
			inputRef={ref}
			onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
			overwrite
		/>
	);
});

export default function AddressForm(props: {
	customer: Customer;
	setCustomer?: any;
	isEdit?: boolean;
	handleEdit?: () => void;
	handleCancelOperation?: () => void;
}) {
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [tempCep, setTempCep] = useState<string>(props.customer?.address?.cep);

	async function handleCepApi(value, customer: Customer, setCustomer) {
		let cepResponse = await cepAutoComplete(value);
		customer.address.state = cepResponse.state;
		customer.address.city = cepResponse.city;
		customer.address.neighbourhood = cepResponse.neighborhood;
		customer.address.street = cepResponse.street;
		setCustomer({ ...customer });
	}

	const fetch = React.useMemo(
		() =>
			debounce(async (request, callback) => {
				if (request.input?.length === 9) {
					handleCepApi(request.input, request.customer, request.setCustomer);
				}
			}, 400),
		[]
	);

	React.useEffect(() => {
		fetch({ input: tempCep, customer: props.customer, setCustomer: props.setCustomer }, () => {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tempCep, fetch]);

	useEffect(() => {
		if (props.customer.address) {
			props.customer.address.cep = tempCep;
			props.setCustomer({ ...props.customer });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tempCep]);

	return (
		<BaseForm>
			<Grid
				container
				columnSpacing={4}
				rowSpacing={2}
				sx={{ backgroundColor: "var(--joy-palette-neutral-50)", borderRadius: "1rem" }}
			>
				<Grid md={12} xs={12}>
					<Box sx={{ display: "flex", justifyContent: "space-between" }}>
						<Typography level="title-lg" sx={{ display: "flex", alignItems: "center", gap: "0.5em" }}>
							<Home />
							Endereço
						</Typography>
						{props.isEdit && (
							<IconButton onClick={() => setIsEdit(!isEdit)} color="primary">
								<EditNote />
							</IconButton>
						)}
					</Box>
				</Grid>
				<Grid md={2} xs={8}>
					<FormControl required={true}>
						<FormLabel>CEP</FormLabel>
						<Input
							id="cep"
							color="primary"
							placeholder="CEP"
							variant="outlined"
							disabled={props.isEdit ? (isEdit ? false : true) : false}
							value={tempCep}
							onChange={(evt) => setTempCep(evt.target.value)}
							slotProps={{ input: { component: TextMaskAdapter } }}
						/>
					</FormControl>
				</Grid>
				<Grid md={5} xs={12}>
					<BaseInput
						value={props.customer?.address?.street}
						onChange={(value) => {
							if (!props.customer.address) props.customer.address = {};
							props.customer.address.street = value;
							props.setCustomer({ ...props.customer });
						}}
						label="Endereço"
						placeholder="Digite seu endereço"
						required
						disabled={props.isEdit ? (isEdit ? false : true) : false}
					/>
				</Grid>
				<Grid md={2} xs={5}>
					<BaseInput
						value={props.customer?.address?.number}
						onChange={(value) => {
							if (!props.customer.address) props.customer.address = {};
							props.customer.address.number = value;
							props.setCustomer({ ...props.customer });
						}}
						label="Número"
						placeholder="Digite o número da sua casa"
						required
						disabled={props.isEdit ? (isEdit ? false : true) : false}
					/>
				</Grid>
				<Grid md={3} xs={12}>
					<FormControl>
						<FormLabel>Bairro</FormLabel>
						<Input
							color="primary"
							placeholder="Bairro"
							variant="outlined"
							disabled={props.isEdit ? (isEdit ? false : true) : false}
							value={props.customer?.address?.neighbourhood}
							onChange={(evt) => {
								if (!props.customer.address) props.customer.address = {};
								props.customer.address.neighbourhood = evt.target.value;
								props.setCustomer({ ...props.customer });
							}}
						/>
					</FormControl>
				</Grid>

				<Grid md={6} xs={12}>
					<FormControl>
						<FormLabel>Cidade</FormLabel>
						<Input
							color="primary"
							placeholder="Cidade"
							variant="outlined"
							disabled={true}
							value={props.customer?.address?.city}
						/>
					</FormControl>
				</Grid>

				<Grid md={6} xs={12}>
					<FormControl>
						<FormLabel>Estado</FormLabel>
						<Input
							color="primary"
							placeholder="Estado"
							variant="outlined"
							disabled={true}
							value={props.customer?.address?.state}
						/>
					</FormControl>
				</Grid>

				{isEdit && (
					<>
						<Grid md={8} xs={8}></Grid>
						<Grid md={4} xs={4} sx={{ display: "flex", gap: "1em", justifyContent: "flex-end" }}>
							<Button
								color="neutral"
								onClick={() => {
									props.handleCancelOperation();
									setIsEdit(false);
								}}
							>
								Cancelar
							</Button>
							<Button
								onClick={() => {
									props.handleEdit();
									setIsEdit(false);
								}}
								color="secondary"
							>
								Salvar
							</Button>
						</Grid>
					</>
				)}
			</Grid>
		</BaseForm>
	);
}
