export const background_data = [
    "abstract.png",
    "whiteBG.jpg",
    "diaDasMaes5.jpg",
    "blobs.png",
    "clouds.png",
    "curveSquare.png",
    "forms.png",
    "hexa.png",
    "paint.png",
    "pointLine.png"
]