import { ArtInterface } from "../../../../interfaces/artInterface";

export const stickers: ArtInterface[] = [
  {
    id: "coffee-bean-filled-roast-brew-svgrepo-com",
    name: "coffee-bean-filled-roast-brew-svgrepo-com.svg",
    src: "/sticker/coffee-bean-filled-roast-brew-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "halloween-horror-pumpkin-svgrepo-com",
    name: "halloween-horror-pumpkin-svgrepo-com.svg",
    src: "/sticker/halloween-horror-pumpkin-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "star-svgrepo-com",
    name: "star-svgrepo-com.svg",
    src: "/sticker/star-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Shapes",
  },
  {
    id: "car-service-svgrepo-com",
    name: "car-service-svgrepo-com.svg",
    src: "/sticker/car-service-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "olympic-torch-svgrepo-com",
    name: "olympic-torch-svgrepo-com.svg",
    src: "/sticker/olympic-torch-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Sports",
  },
  {
    id: "christmas-bulb-svgrepo-com",
    name: "christmas-bulb-svgrepo-com.svg",
    src: "/sticker/christmas-bulb-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "food-svgrepo-com",
    name: "food-svgrepo-com.svg",
    src: "/sticker/food-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "car-svgrepo-com (4)",
    name: "car-svgrepo-com (4).svg",
    src: "/sticker/car-svgrepo-com (4).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "cake-svgrepo-com (2)",
    name: "cake-svgrepo-com (2).svg",
    src: "/sticker/cake-svgrepo-com (2).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "drawing-svgrepo-com",
    name: "drawing-svgrepo-com.svg",
    src: "/sticker/drawing-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Art",
  },
  {
    id: "halloween-scary-spider-svgrepo-com",
    name: "halloween-scary-spider-svgrepo-com.svg",
    src: "/sticker/halloween-scary-spider-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "coding-html-svgrepo-com",
    name: "coding-html-svgrepo-com.svg",
    src: "/sticker/coding-html-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Work",
  },
  {
    id: "tie-accessory-svgrepo-com",
    name: "tie-accessory-svgrepo-com.svg",
    src: "/sticker/tie-accessory-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fashion",
  },
  {
    id: "sorceress-witch-svgrepo-com",
    name: "sorceress-witch-svgrepo-com.svg",
    src: "/sticker/sorceress-witch-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "chicken-svgrepo-com",
    name: "chicken-svgrepo-com.svg",
    src: "/sticker/chicken-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "holy-chalice-faith-svgrepo-com (1)",
    name: "holy-chalice-faith-svgrepo-com (1).svg",
    src: "/sticker/holy-chalice-faith-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Religion",
  },
  {
    id: "volleyball-svgrepo-com",
    name: "volleyball-svgrepo-com.svg",
    src: "/sticker/volleyball-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Sports",
  },
  {
    id: "taj-mahal-svgrepo-com",
    name: "taj-mahal-svgrepo-com.svg",
    src: "/sticker/taj-mahal-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "monster-svgrepo-com",
    name: "monster-svgrepo-com.svg",
    src: "/sticker/monster-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "wedding-cake-cook-svgrepo-com",
    name: "wedding-cake-cook-svgrepo-com.svg",
    src: "/sticker/wedding-cake-cook-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "last-supper-easter-svgrepo-com",
    name: "last-supper-easter-svgrepo-com.svg",
    src: "/sticker/last-supper-easter-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Religion",
  },
  {
    id: "party-popper-svgrepo-com",
    name: "party-popper-svgrepo-com.svg",
    src: "/sticker/party-popper-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "cake-turkey-svgrepo-com",
    name: "cake-turkey-svgrepo-com.svg",
    src: "/sticker/cake-turkey-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "music-player-retro-svgrepo-com",
    name: "music-player-retro-svgrepo-com.svg",
    src: "/sticker/music-player-retro-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Art",
  },
  {
    id: "leaf-organic-2-svgrepo-com",
    name: "leaf-organic-2-svgrepo-com.svg",
    src: "/sticker/leaf-organic-2-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "leaning-tower-of-pisa-svgrepo-com",
    name: "leaning-tower-of-pisa-svgrepo-com.svg",
    src: "/sticker/leaning-tower-of-pisa-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "christmas-hat-svgrepo-com",
    name: "christmas-hat-svgrepo-com.svg",
    src: "/sticker/christmas-hat-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "car-svgrepo-com (2)",
    name: "car-svgrepo-com (2).svg",
    src: "/sticker/car-svgrepo-com (2).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "christmas-decoration-door-svgrepo-com",
    name: "christmas-decoration-door-svgrepo-com.svg",
    src: "/sticker/christmas-decoration-door-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "cactus-2-svgrepo-com",
    name: "cactus-2-svgrepo-com.svg",
    src: "/sticker/cactus-2-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "heart-wings-angel-svgrepo-com",
    name: "heart-wings-angel-svgrepo-com.svg",
    src: "/sticker/heart-wings-angel-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Love",
  },
  {
    id: "potion-svgrepo-com",
    name: "potion-svgrepo-com.svg",
    src: "/sticker/potion-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "colosseum-svgrepo-com",
    name: "colosseum-svgrepo-com.svg",
    src: "/sticker/colosseum-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "easter-bunny-rabbit-svgrepo-com (1)",
    name: "easter-bunny-rabbit-svgrepo-com (1).svg",
    src: "/sticker/easter-bunny-rabbit-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "cake-svgrepo-com",
    name: "cake-svgrepo-com.svg",
    src: "/sticker/cake-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "sydney-opera-house-svgrepo-com",
    name: "sydney-opera-house-svgrepo-com.svg",
    src: "/sticker/sydney-opera-house-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "burj-al-arab-svgrepo-com",
    name: "burj-al-arab-svgrepo-com.svg",
    src: "/sticker/burj-al-arab-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "leaves-5-svgrepo-com",
    name: "leaves-5-svgrepo-com.svg",
    src: "/sticker/leaves-5-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "car-svgrepo-com (3)",
    name: "car-svgrepo-com (3).svg",
    src: "/sticker/car-svgrepo-com (3).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "flamingo-svgrepo-com",
    name: "flamingo-svgrepo-com.svg",
    src: "/sticker/flamingo-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "discover-svgrepo-com",
    name: "discover-svgrepo-com.svg",
    src: "/sticker/discover-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "christmas-tree-part-2-svgrepo-com",
    name: "christmas-tree-part-2-svgrepo-com.svg",
    src: "/sticker/christmas-tree-part-2-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "fox-svgrepo-com",
    name: "fox-svgrepo-com.svg",
    src: "/sticker/fox-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "latin-cross-svgrepo-com",
    name: "latin-cross-svgrepo-com.svg",
    src: "/sticker/latin-cross-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Religion",
  },
  {
    id: "christ-the-redeemer-svgrepo-com (1)",
    name: "christ-the-redeemer-svgrepo-com (1).svg",
    src: "/sticker/christ-the-redeemer-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "github-142-svgrepo-com",
    name: "github-142-svgrepo-com.svg",
    src: "/sticker/github-142-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Work",
  },
  {
    id: "tie-svgrepo-com (4)",
    name: "tie-svgrepo-com (4).svg",
    src: "/sticker/tie-svgrepo-com (4).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fashion",
  },
  {
    id: "tie-svgrepo-com (2)",
    name: "tie-svgrepo-com (2).svg",
    src: "/sticker/tie-svgrepo-com (2).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fashion",
  },
  {
    id: "jesus-god-svgrepo-com",
    name: "jesus-god-svgrepo-com.svg",
    src: "/sticker/jesus-god-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Religion",
  },
  {
    id: "car-crash-svgrepo-com",
    name: "car-crash-svgrepo-com.svg",
    src: "/sticker/car-crash-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "magnifier-svgrepo-com",
    name: "magnifier-svgrepo-com.svg",
    src: "/sticker/magnifier-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "armor-svgrepo-com",
    name: "armor-svgrepo-com.svg",
    src: "/sticker/armor-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "electric-guitar-svgrepo-com",
    name: "electric-guitar-svgrepo-com.svg",
    src: "/sticker/electric-guitar-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Art",
  },
  {
    id: "coffee-tea-hot-beverage-drink-steaming-svgrepo-com",
    name: "coffee-tea-hot-beverage-drink-steaming-svgrepo-com.svg",
    src: "/sticker/coffee-tea-hot-beverage-drink-steaming-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "christmas-sock-svgrepo-com",
    name: "christmas-sock-svgrepo-com.svg",
    src: "/sticker/christmas-sock-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "wheat-svgrepo-com",
    name: "wheat-svgrepo-com.svg",
    src: "/sticker/wheat-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "wedding-svgrepo-com",
    name: "wedding-svgrepo-com.svg",
    src: "/sticker/wedding-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "crown-svgrepo-com (1)",
    name: "crown-svgrepo-com (1).svg",
    src: "/sticker/crown-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "race-flag-svgrepo-com",
    name: "race-flag-svgrepo-com.svg",
    src: "/sticker/race-flag-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Sports",
  },
  {
    id: "shape-embellishment-svgrepo-com",
    name: "shape-embellishment-svgrepo-com.svg",
    src: "/sticker/shape-embellishment-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Shapes",
  },
  {
    id: "monster-svgrepo-com (1)",
    name: "monster-svgrepo-com (1).svg",
    src: "/sticker/monster-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "halloween-bats-svgrepo-com",
    name: "halloween-bats-svgrepo-com.svg",
    src: "/sticker/halloween-bats-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "car-black-svgrepo-com",
    name: "car-black-svgrepo-com.svg",
    src: "/sticker/car-black-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "deer-svgrepo-com",
    name: "deer-svgrepo-com.svg",
    src: "/sticker/deer-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "doctor-svgrepo-com",
    name: "doctor-svgrepo-com.svg",
    src: "/sticker/doctor-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Work",
  },
  {
    id: "music-svgrepo-com",
    name: "music-svgrepo-com.svg",
    src: "/sticker/music-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Art",
  },
  {
    id: "eiffel-tower-svgrepo-com",
    name: "eiffel-tower-svgrepo-com.svg",
    src: "/sticker/eiffel-tower-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "wedding-cake-sweet-svgrepo-com",
    name: "wedding-cake-sweet-svgrepo-com.svg",
    src: "/sticker/wedding-cake-sweet-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "religion-svgrepo-com",
    name: "religion-svgrepo-com.svg",
    src: "/sticker/religion-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Religion",
  },
  {
    id: "heart-svgrepo-com",
    name: "heart-svgrepo-com.svg",
    src: "/sticker/heart-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Love",
  },
  {
    id: "parasol-svgrepo-com",
    name: "parasol-svgrepo-com.svg",
    src: "/sticker/parasol-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "coding-programming-language-svgrepo-com",
    name: "coding-programming-language-svgrepo-com.svg",
    src: "/sticker/coding-programming-language-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Work",
  },
  {
    id: "alarm-clock-svgrepo-com",
    name: "alarm-clock-svgrepo-com.svg",
    src: "/sticker/alarm-clock-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "music-player-svgrepo-com",
    name: "music-player-svgrepo-com.svg",
    src: "/sticker/music-player-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Art",
  },
  {
    id: "easter-bunny-rabbit-svgrepo-com",
    name: "easter-bunny-rabbit-svgrepo-com.svg",
    src: "/sticker/easter-bunny-rabbit-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "christmas-cookie-dessert-svgrepo-com",
    name: "christmas-cookie-dessert-svgrepo-com.svg",
    src: "/sticker/christmas-cookie-dessert-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "tie-svgrepo-com",
    name: "tie-svgrepo-com.svg",
    src: "/sticker/tie-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fashion",
  },
  {
    id: "trophy-svgrepo-com",
    name: "trophy-svgrepo-com.svg",
    src: "/sticker/trophy-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Sports",
  },
  {
    id: "music-player-record-svgrepo-com",
    name: "music-player-record-svgrepo-com.svg",
    src: "/sticker/music-player-record-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Art",
  },
  {
    id: "hat-birthday-svgrepo-com",
    name: "hat-birthday-svgrepo-com.svg",
    src: "/sticker/hat-birthday-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "branch-svgrepo-com",
    name: "branch-svgrepo-com.svg",
    src: "/sticker/branch-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "diving-goggles-svgrepo-com",
    name: "diving-goggles-svgrepo-com.svg",
    src: "/sticker/diving-goggles-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "bird-svgrepo-com",
    name: "bird-svgrepo-com.svg",
    src: "/sticker/bird-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "space-svgrepo-com",
    name: "space-svgrepo-com.svg",
    src: "/sticker/space-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "alcohol-beer-celebration-cheers-drink-happy-svgrepo-com",
    name: "alcohol-beer-celebration-cheers-drink-happy-svgrepo-com.svg",
    src: "/sticker/alcohol-beer-celebration-cheers-drink-happy-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "music-svgrepo-com (6)",
    name: "music-svgrepo-com (6).svg",
    src: "/sticker/music-svgrepo-com (6).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Art",
  },
  {
    id: "yin-yang-balance-religion-wisdom-svgrepo-com",
    name: "yin-yang-balance-religion-wisdom-svgrepo-com.svg",
    src: "/sticker/yin-yang-balance-religion-wisdom-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Religion",
  },
  {
    id: "celebration-flag-garland-svgrepo-com (1)",
    name: "celebration-flag-garland-svgrepo-com (1).svg",
    src: "/sticker/celebration-flag-garland-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "heart-broken-svgrepo-com",
    name: "heart-broken-svgrepo-com.svg",
    src: "/sticker/heart-broken-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Love",
  },
  {
    id: "jesus-christ-svgrepo-com",
    name: "jesus-christ-svgrepo-com.svg",
    src: "/sticker/jesus-christ-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Religion",
  },
  {
    id: "heart-svgrepo-com (2)",
    name: "heart-svgrepo-com (2).svg",
    src: "/sticker/heart-svgrepo-com (2).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Love",
  },
  {
    id: "halloween-hat-magic-svgrepo-com",
    name: "halloween-hat-magic-svgrepo-com.svg",
    src: "/sticker/halloween-hat-magic-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "music-svgrepo-com (2)",
    name: "music-svgrepo-com (2).svg",
    src: "/sticker/music-svgrepo-com (2).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Art",
  },
  {
    id: "christmas-sugarcane-svgrepo-com",
    name: "christmas-sugarcane-svgrepo-com.svg",
    src: "/sticker/christmas-sugarcane-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "statue-of-liberty-svgrepo-com",
    name: "statue-of-liberty-svgrepo-com.svg",
    src: "/sticker/statue-of-liberty-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "crown-user-svgrepo-com",
    name: "crown-user-svgrepo-com.svg",
    src: "/sticker/crown-user-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "volleyball-svgrepo-com (1)",
    name: "volleyball-svgrepo-com (1).svg",
    src: "/sticker/volleyball-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Sports",
  },
  {
    id: "car-svgrepo-com",
    name: "car-svgrepo-com.svg",
    src: "/sticker/car-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "easter-island-svgrepo-com",
    name: "easter-island-svgrepo-com.svg",
    src: "/sticker/easter-island-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "heart-alt-svgrepo-com",
    name: "heart-alt-svgrepo-com.svg",
    src: "/sticker/heart-alt-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Love",
  },
  {
    id: "sun-svgrepo-com (1)",
    name: "sun-svgrepo-com (1).svg",
    src: "/sticker/sun-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "christmas-santa-and-reindeer-svgrepo-com",
    name: "christmas-santa-and-reindeer-svgrepo-com.svg",
    src: "/sticker/christmas-santa-and-reindeer-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "angel-svgrepo-com",
    name: "angel-svgrepo-com.svg",
    src: "/sticker/angel-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Religion",
  },
  {
    id: "arc-de-triomphe-svgrepo-com",
    name: "arc-de-triomphe-svgrepo-com.svg",
    src: "/sticker/arc-de-triomphe-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "carrot-svgrepo-com",
    name: "carrot-svgrepo-com.svg",
    src: "/sticker/carrot-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "lawyer-svgrepo-com",
    name: "lawyer-svgrepo-com.svg",
    src: "/sticker/lawyer-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "tie-svgrepo-com (1)",
    name: "tie-svgrepo-com (1).svg",
    src: "/sticker/tie-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fashion",
  },
  {
    id: "castle-svgrepo-com",
    name: "castle-svgrepo-com.svg",
    src: "/sticker/castle-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "tape-recorder-svgrepo-com",
    name: "tape-recorder-svgrepo-com.svg",
    src: "/sticker/tape-recorder-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "desk-lamp-svgrepo-com",
    name: "desk-lamp-svgrepo-com.svg",
    src: "/sticker/desk-lamp-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "tree-svgrepo-com (6)",
    name: "tree-svgrepo-com (6).svg",
    src: "/sticker/tree-svgrepo-com (6).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "student-svgrepo-com",
    name: "student-svgrepo-com.svg",
    src: "/sticker/student-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Work",
  },
  {
    id: "pen-svgrepo-com",
    name: "pen-svgrepo-com.svg",
    src: "/sticker/pen-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "christ-the-redeemer-svgrepo-com",
    name: "christ-the-redeemer-svgrepo-com.svg",
    src: "/sticker/christ-the-redeemer-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "coffee-cup-tea-svgrepo-com",
    name: "coffee-cup-tea-svgrepo-com.svg",
    src: "/sticker/coffee-cup-tea-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "christ-the-redeemer-svgrepo-com (2)",
    name: "christ-the-redeemer-svgrepo-com (2).svg",
    src: "/sticker/christ-the-redeemer-svgrepo-com (2).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "star-fall-svgrepo-com",
    name: "star-fall-svgrepo-com.svg",
    src: "/sticker/star-fall-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Shapes",
  },
  {
    id: "cupcake-svgrepo-com",
    name: "cupcake-svgrepo-com.svg",
    src: "/sticker/cupcake-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "sports-medal-svgrepo-com",
    name: "sports-medal-svgrepo-com.svg",
    src: "/sticker/sports-medal-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Sports",
  },
  {
    id: "dumbbel-svgrepo-com",
    name: "dumbbel-svgrepo-com.svg",
    src: "/sticker/dumbbel-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Sports",
  },
  {
    id: "wedding-rings-wedding-svgrepo-com",
    name: "wedding-rings-wedding-svgrepo-com.svg",
    src: "/sticker/wedding-rings-wedding-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "hat-svgrepo-com",
    name: "hat-svgrepo-com.svg",
    src: "/sticker/hat-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fashion",
  },
  {
    id: "coffee-svgrepo-com (2)",
    name: "coffee-svgrepo-com (2).svg",
    src: "/sticker/coffee-svgrepo-com (2).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "potted-cactus-svgrepo-com",
    name: "potted-cactus-svgrepo-com.svg",
    src: "/sticker/potted-cactus-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "cake-cup-dessert-svgrepo-com",
    name: "cake-cup-dessert-svgrepo-com.svg",
    src: "/sticker/cake-cup-dessert-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "bird-love-heart-svgrepo-com",
    name: "bird-love-heart-svgrepo-com.svg",
    src: "/sticker/bird-love-heart-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "celebration-flag-garland-svgrepo-com",
    name: "celebration-flag-garland-svgrepo-com.svg",
    src: "/sticker/celebration-flag-garland-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "religion-cross-svgrepo-com",
    name: "religion-cross-svgrepo-com.svg",
    src: "/sticker/religion-cross-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Religion",
  },
  {
    id: "christmas-cookie-dessert-2-svgrepo-com",
    name: "christmas-cookie-dessert-2-svgrepo-com.svg",
    src: "/sticker/christmas-cookie-dessert-2-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "london-bridge-svgrepo-com",
    name: "london-bridge-svgrepo-com.svg",
    src: "/sticker/london-bridge-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "tree-svgrepo-com",
    name: "tree-svgrepo-com.svg",
    src: "/sticker/tree-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "corn-svgrepo-com",
    name: "corn-svgrepo-com.svg",
    src: "/sticker/corn-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "crown-svgrepo-com (4)",
    name: "crown-svgrepo-com (4).svg",
    src: "/sticker/crown-svgrepo-com (4).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "tree-svgrepo-com (5)",
    name: "tree-svgrepo-com (5).svg",
    src: "/sticker/tree-svgrepo-com (5).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "cake-dessert-food-svgrepo-com",
    name: "cake-dessert-food-svgrepo-com.svg",
    src: "/sticker/cake-dessert-food-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "crown-svgrepo-com (2)",
    name: "crown-svgrepo-com (2).svg",
    src: "/sticker/crown-svgrepo-com (2).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "music-svgrepo-com (1)",
    name: "music-svgrepo-com (1).svg",
    src: "/sticker/music-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Art",
  },
  {
    id: "doughnut-svgrepo-com",
    name: "doughnut-svgrepo-com.svg",
    src: "/sticker/doughnut-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "japanese-shrine-svgrepo-com",
    name: "japanese-shrine-svgrepo-com.svg",
    src: "/sticker/japanese-shrine-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "christmas-snow-snowman-svgrepo-com",
    name: "christmas-snow-snowman-svgrepo-com.svg",
    src: "/sticker/christmas-snow-snowman-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "ballons-celebration-heart-svgrepo-com",
    name: "ballons-celebration-heart-svgrepo-com.svg",
    src: "/sticker/ballons-celebration-heart-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "christmas-decoration-festival-svgrepo-com",
    name: "christmas-decoration-festival-svgrepo-com.svg",
    src: "/sticker/christmas-decoration-festival-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "gamepad-svgrepo-com",
    name: "gamepad-svgrepo-com.svg",
    src: "/sticker/gamepad-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "parthenon-svgrepo-com",
    name: "parthenon-svgrepo-com.svg",
    src: "/sticker/parthenon-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "bushes-of-leaves-svgrepo-com",
    name: "bushes-of-leaves-svgrepo-com.svg",
    src: "/sticker/bushes-of-leaves-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "peach-svgrepo-com",
    name: "peach-svgrepo-com.svg",
    src: "/sticker/peach-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "car-svgrepo-com (1)",
    name: "car-svgrepo-com (1).svg",
    src: "/sticker/car-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "doctor-svgrepo-com (2)",
    name: "doctor-svgrepo-com (2).svg",
    src: "/sticker/doctor-svgrepo-com (2).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Work",
  },
  {
    id: "movie-18-plus-rating-svgrepo-com",
    name: "movie-18-plus-rating-svgrepo-com.svg",
    src: "/sticker/movie-18-plus-rating-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Art",
  },
  {
    id: "music-instruments-drums-svgrepo-com",
    name: "music-instruments-drums-svgrepo-com.svg",
    src: "/sticker/music-instruments-drums-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Art",
  },
  {
    id: "devrant-svgrepo-com",
    name: "devrant-svgrepo-com.svg",
    src: "/sticker/devrant-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Work",
  },
  {
    id: "mother-and-baby-svgrepo-com",
    name: "mother-and-baby-svgrepo-com.svg",
    src: "/sticker/mother-and-baby-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Love",
  },
  {
    id: "holy-chalice-faith-svgrepo-com",
    name: "holy-chalice-faith-svgrepo-com.svg",
    src: "/sticker/holy-chalice-faith-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Religion",
  },
  {
    id: "grape-svgrepo-com",
    name: "grape-svgrepo-com.svg",
    src: "/sticker/grape-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "law-hammer-svgrepo-com",
    name: "law-hammer-svgrepo-com.svg",
    src: "/sticker/law-hammer-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "movie-clapperboard-part-2-svgrepo-com",
    name: "movie-clapperboard-part-2-svgrepo-com.svg",
    src: "/sticker/movie-clapperboard-part-2-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Art",
  },
  {
    id: "bed-hotel-heart-svgrepo-com",
    name: "bed-hotel-heart-svgrepo-com.svg",
    src: "/sticker/bed-hotel-heart-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "crown-svgrepo-com",
    name: "crown-svgrepo-com.svg",
    src: "/sticker/crown-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "suitcase-svgrepo-com",
    name: "suitcase-svgrepo-com.svg",
    src: "/sticker/suitcase-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "chicken-svgrepo-com (1)",
    name: "chicken-svgrepo-com (1).svg",
    src: "/sticker/chicken-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "treant-tree-svgrepo-com",
    name: "treant-tree-svgrepo-com.svg",
    src: "/sticker/treant-tree-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "tree-svgrepo-com (4)",
    name: "tree-svgrepo-com (4).svg",
    src: "/sticker/tree-svgrepo-com (4).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "camera-svgrepo-com",
    name: "camera-svgrepo-com.svg",
    src: "/sticker/camera-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "racing-helmet-svgrepo-com",
    name: "racing-helmet-svgrepo-com.svg",
    src: "/sticker/racing-helmet-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Sports",
  },
  {
    id: "leaves-2-svgrepo-com",
    name: "leaves-2-svgrepo-com.svg",
    src: "/sticker/leaves-2-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "wedding-rings-jewel-svgrepo-com",
    name: "wedding-rings-jewel-svgrepo-com.svg",
    src: "/sticker/wedding-rings-jewel-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "sun-hat-svgrepo-com",
    name: "sun-hat-svgrepo-com.svg",
    src: "/sticker/sun-hat-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fashion",
  },
  {
    id: "heart-glasses-svgrepo-com",
    name: "heart-glasses-svgrepo-com.svg",
    src: "/sticker/heart-glasses-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Love",
  },
  {
    id: "doctor-svgrepo-com (1)",
    name: "doctor-svgrepo-com (1).svg",
    src: "/sticker/doctor-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Work",
  },
  {
    id: "corn-svgrepo-com (1)",
    name: "corn-svgrepo-com (1).svg",
    src: "/sticker/corn-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "sphinx-svgrepo-com",
    name: "sphinx-svgrepo-com.svg",
    src: "/sticker/sphinx-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "heart4-svgrepo-com",
    name: "heart4-svgrepo-com.svg",
    src: "/sticker/heart4-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Love",
  },
  {
    id: "halloween-pumpkin-svgrepo-com",
    name: "halloween-pumpkin-svgrepo-com.svg",
    src: "/sticker/halloween-pumpkin-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "car-svgrepo-com (5)",
    name: "car-svgrepo-com (5).svg",
    src: "/sticker/car-svgrepo-com (5).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "werewolf-svgrepo-com",
    name: "werewolf-svgrepo-com.svg",
    src: "/sticker/werewolf-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "halloween-spider-web-svgrepo-com",
    name: "halloween-spider-web-svgrepo-com.svg",
    src: "/sticker/halloween-spider-web-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "sports-soccer-svgrepo-com",
    name: "sports-soccer-svgrepo-com.svg",
    src: "/sticker/sports-soccer-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Sports",
  },
  {
    id: "star-track-svgrepo-com",
    name: "star-track-svgrepo-com.svg",
    src: "/sticker/star-track-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Shapes",
  },
  {
    id: "racing-svgrepo-com",
    name: "racing-svgrepo-com.svg",
    src: "/sticker/racing-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Sports",
  },
  {
    id: "camera-svgrepo-com (1)",
    name: "camera-svgrepo-com (1).svg",
    src: "/sticker/camera-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "sun-flower-svgrepo-com",
    name: "sun-flower-svgrepo-com.svg",
    src: "/sticker/sun-flower-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "clinking-beer-mugs-svgrepo-com",
    name: "clinking-beer-mugs-svgrepo-com.svg",
    src: "/sticker/clinking-beer-mugs-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "cactus-svgrepo-com",
    name: "cactus-svgrepo-com.svg",
    src: "/sticker/cactus-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "bonfire-campfire-svgrepo-com",
    name: "bonfire-campfire-svgrepo-com.svg",
    src: "/sticker/bonfire-campfire-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "big-ben-svgrepo-com",
    name: "big-ben-svgrepo-com.svg",
    src: "/sticker/big-ben-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "spell-book-svgrepo-com",
    name: "spell-book-svgrepo-com.svg",
    src: "/sticker/spell-book-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "music-itunes-svgrepo-com",
    name: "music-itunes-svgrepo-com.svg",
    src: "/sticker/music-itunes-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Art",
  },
  {
    id: "candle-svgrepo-com",
    name: "candle-svgrepo-com.svg",
    src: "/sticker/candle-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "map-svgrepo-com",
    name: "map-svgrepo-com.svg",
    src: "/sticker/map-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "christ-the-redeemer-brazil-svgrepo-com",
    name: "christ-the-redeemer-brazil-svgrepo-com.svg",
    src: "/sticker/christ-the-redeemer-brazil-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "fish-cake-with-swirl-non-vegetarian-svgrepo-com",
    name: "fish-cake-with-swirl-non-vegetarian-svgrepo-com.svg",
    src: "/sticker/fish-cake-with-swirl-non-vegetarian-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "sports-trophy-cup-with-leaves-branches-svgrepo-com",
    name: "sports-trophy-cup-with-leaves-branches-svgrepo-com.svg",
    src: "/sticker/sports-trophy-cup-with-leaves-branches-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Sports",
  },
  {
    id: "spell-scroll-svgrepo-com",
    name: "spell-scroll-svgrepo-com.svg",
    src: "/sticker/spell-scroll-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "christmas-decorative-globe-svgrepo-com",
    name: "christmas-decorative-globe-svgrepo-com.svg",
    src: "/sticker/christmas-decorative-globe-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "tree-svgrepo-com (1)",
    name: "tree-svgrepo-com (1).svg",
    src: "/sticker/tree-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "kiwi-fruit-svgrepo-com",
    name: "kiwi-fruit-svgrepo-com.svg",
    src: "/sticker/kiwi-fruit-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "stadium-svgrepo-com",
    name: "stadium-svgrepo-com.svg",
    src: "/sticker/stadium-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Sports",
  },
  {
    id: "music-svgrepo-com (3)",
    name: "music-svgrepo-com (3).svg",
    src: "/sticker/music-svgrepo-com (3).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Art",
  },
  {
    id: "pen-and-ruler-svgrepo-com",
    name: "pen-and-ruler-svgrepo-com.svg",
    src: "/sticker/pen-and-ruler-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "coffee-svgrepo-com (1)",
    name: "coffee-svgrepo-com (1).svg",
    src: "/sticker/coffee-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "heart-svgrepo-com (1)",
    name: "heart-svgrepo-com (1).svg",
    src: "/sticker/heart-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Love",
  },
  {
    id: "easter-egg-easter-svgrepo-com",
    name: "easter-egg-easter-svgrepo-com.svg",
    src: "/sticker/easter-egg-easter-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "christ-the-redeemer-brazil-svgrepo-com (1)",
    name: "christ-the-redeemer-brazil-svgrepo-com (1).svg",
    src: "/sticker/christ-the-redeemer-brazil-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "ladybug-bug-svgrepo-com",
    name: "ladybug-bug-svgrepo-com.svg",
    src: "/sticker/ladybug-bug-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "christianity-religion-svgrepo-com",
    name: "christianity-religion-svgrepo-com.svg",
    src: "/sticker/christianity-religion-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Religion",
  },
  {
    id: "watermelon-svgrepo-com",
    name: "watermelon-svgrepo-com.svg",
    src: "/sticker/watermelon-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "earphone-svgrepo-com",
    name: "earphone-svgrepo-com.svg",
    src: "/sticker/earphone-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "mount-fuji-svgrepo-com",
    name: "mount-fuji-svgrepo-com.svg",
    src: "/sticker/mount-fuji-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Travel",
  },
  {
    id: "om-religion-svgrepo-com",
    name: "om-religion-svgrepo-com.svg",
    src: "/sticker/om-religion-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Religion",
  },
  {
    id: "halloween-ghost-svgrepo-com",
    name: "halloween-ghost-svgrepo-com.svg",
    src: "/sticker/halloween-ghost-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "halloween-tree-trunk-with-spirals-svgrepo-com",
    name: "halloween-tree-trunk-with-spirals-svgrepo-com.svg",
    src: "/sticker/halloween-tree-trunk-with-spirals-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "leaves-4-svgrepo-com",
    name: "leaves-4-svgrepo-com.svg",
    src: "/sticker/leaves-4-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "sun-svgrepo-com",
    name: "sun-svgrepo-com.svg",
    src: "/sticker/sun-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "mother-with-baby-in-arms-svgrepo-com",
    name: "mother-with-baby-in-arms-svgrepo-com.svg",
    src: "/sticker/mother-with-baby-in-arms-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Love",
  },
  {
    id: "soul-svgrepo-com",
    name: "soul-svgrepo-com.svg",
    src: "/sticker/soul-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "movie-svgrepo-com",
    name: "movie-svgrepo-com.svg",
    src: "/sticker/movie-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Art",
  },
  {
    id: "leaf-svgrepo-com (1)",
    name: "leaf-svgrepo-com (1).svg",
    src: "/sticker/leaf-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "doctor-drug-health-healthcare-hospital-medical-svgrepo-com",
    name: "doctor-drug-health-healthcare-hospital-medical-svgrepo-com.svg",
    src: "/sticker/doctor-drug-health-healthcare-hospital-medical-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Work",
  },
  {
    id: "heart2-svgrepo-com",
    name: "heart2-svgrepo-com.svg",
    src: "/sticker/heart2-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Love",
  },
  {
    id: "medicine-11-svgrepo-com",
    name: "medicine-11-svgrepo-com.svg",
    src: "/sticker/medicine-11-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Work",
  },
  {
    id: "bible-religion-christian-holy-svgrepo-com",
    name: "bible-religion-christian-holy-svgrepo-com.svg",
    src: "/sticker/bible-religion-christian-holy-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Religion",
  },
  {
    id: "leaves-3-svgrepo-com",
    name: "leaves-3-svgrepo-com.svg",
    src: "/sticker/leaves-3-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "music-svgrepo-com (4)",
    name: "music-svgrepo-com (4).svg",
    src: "/sticker/music-svgrepo-com (4).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Art",
  },
  {
    id: "movie-svgrepo-com (1)",
    name: "movie-svgrepo-com (1).svg",
    src: "/sticker/movie-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Art",
  },
  {
    id: "music-instruments-concert-svgrepo-com",
    name: "music-instruments-concert-svgrepo-com.svg",
    src: "/sticker/music-instruments-concert-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Art",
  },
  {
    id: "birthday-cake-svgrepo-com (1)",
    name: "birthday-cake-svgrepo-com (1).svg",
    src: "/sticker/birthday-cake-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "soda-water-svgrepo-com",
    name: "soda-water-svgrepo-com.svg",
    src: "/sticker/soda-water-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "snow-mountain-svgrepo-com",
    name: "snow-mountain-svgrepo-com.svg",
    src: "/sticker/snow-mountain-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "yagna-ritual-svgrepo-com",
    name: "yagna-ritual-svgrepo-com.svg",
    src: "/sticker/yagna-ritual-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Religion",
  },
  {
    id: "mother-family-svgrepo-com",
    name: "mother-family-svgrepo-com.svg",
    src: "/sticker/mother-family-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Love",
  },
  {
    id: "doctor-svgrepo-com (3)",
    name: "doctor-svgrepo-com (3).svg",
    src: "/sticker/doctor-svgrepo-com (3).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Work",
  },
  {
    id: "christmas-bell-svgrepo-com",
    name: "christmas-bell-svgrepo-com.svg",
    src: "/sticker/christmas-bell-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "unicorn-svgrepo-com",
    name: "unicorn-svgrepo-com.svg",
    src: "/sticker/unicorn-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "music-play-svgrepo-com",
    name: "music-play-svgrepo-com.svg",
    src: "/sticker/music-play-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Art",
  },
  {
    id: "tree-svgrepo-com (2)",
    name: "tree-svgrepo-com (2).svg",
    src: "/sticker/tree-svgrepo-com (2).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "cake-svgrepo-com (1)",
    name: "cake-svgrepo-com (1).svg",
    src: "/sticker/cake-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "bonfire-flame-svgrepo-com",
    name: "bonfire-flame-svgrepo-com.svg",
    src: "/sticker/bonfire-flame-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "halloween-monster-animal-head-with-big-open-mouth-svgrepo-com",
    name: "halloween-monster-animal-head-with-big-open-mouth-svgrepo-com.svg",
    src: "/sticker/halloween-monster-animal-head-with-big-open-mouth-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "mug-svgrepo-com",
    name: "mug-svgrepo-com.svg",
    src: "/sticker/mug-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "tree-svgrepo-com (3)",
    name: "tree-svgrepo-com (3).svg",
    src: "/sticker/tree-svgrepo-com (3).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "jesus-svgrepo-com",
    name: "jesus-svgrepo-com.svg",
    src: "/sticker/jesus-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Religion",
  },
  {
    id: "pamela-hat-svgrepo-com",
    name: "pamela-hat-svgrepo-com.svg",
    src: "/sticker/pamela-hat-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fashion",
  },
  {
    id: "potion-svgrepo-com (1)",
    name: "potion-svgrepo-com (1).svg",
    src: "/sticker/potion-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "tie-svgrepo-com (3)",
    name: "tie-svgrepo-com (3).svg",
    src: "/sticker/tie-svgrepo-com (3).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fashion",
  },
  {
    id: "alien-svgrepo-com",
    name: "alien-svgrepo-com.svg",
    src: "/sticker/alien-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "halloween-horror-skeleton-2-svgrepo-com",
    name: "halloween-horror-skeleton-2-svgrepo-com.svg",
    src: "/sticker/halloween-horror-skeleton-2-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "halloween-owl-spooky-svgrepo-com",
    name: "halloween-owl-spooky-svgrepo-com.svg",
    src: "/sticker/halloween-owl-spooky-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "a-female-doctor-wearing-a-surgical-gown-svgrepo-com",
    name: "a-female-doctor-wearing-a-surgical-gown-svgrepo-com.svg",
    src: "/sticker/a-female-doctor-wearing-a-surgical-gown-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Work",
  },
  {
    id: "sun-sunrise-svgrepo-com",
    name: "sun-sunrise-svgrepo-com.svg",
    src: "/sticker/sun-sunrise-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "celebration-day-fireworks-svgrepo-com",
    name: "celebration-day-fireworks-svgrepo-com.svg",
    src: "/sticker/celebration-day-fireworks-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "strawberry-svgrepo-com",
    name: "strawberry-svgrepo-com.svg",
    src: "/sticker/strawberry-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "adventure-map-svgrepo-com",
    name: "adventure-map-svgrepo-com.svg",
    src: "/sticker/adventure-map-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "dev-svgrepo-com",
    name: "dev-svgrepo-com.svg",
    src: "/sticker/dev-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Work",
  },
  {
    id: "difficulty-svgrepo-com",
    name: "difficulty-svgrepo-com.svg",
    src: "/sticker/difficulty-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "sports-svgrepo-com",
    name: "sports-svgrepo-com.svg",
    src: "/sticker/sports-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Sports",
  },
  {
    id: "business-person-to-explain-right-hand-svgrepo-com",
    name: "business-person-to-explain-right-hand-svgrepo-com.svg",
    src: "/sticker/business-person-to-explain-right-hand-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "globe-svgrepo-com",
    name: "globe-svgrepo-com.svg",
    src: "/sticker/globe-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "airplane-travel-heart-svgrepo-com",
    name: "airplane-travel-heart-svgrepo-com.svg",
    src: "/sticker/airplane-travel-heart-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "coffee-svgrepo-com",
    name: "coffee-svgrepo-com.svg",
    src: "/sticker/coffee-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "read-svgrepo-com",
    name: "read-svgrepo-com.svg",
    src: "/sticker/read-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "music-note-svgrepo-com",
    name: "music-note-svgrepo-com.svg",
    src: "/sticker/music-note-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Art",
  },
  {
    id: "bible-religion-svgrepo-com",
    name: "bible-religion-svgrepo-com.svg",
    src: "/sticker/bible-religion-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Religion",
  },
  {
    id: "leaf-svgrepo-com",
    name: "leaf-svgrepo-com.svg",
    src: "/sticker/leaf-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "wheat-svgrepo-com (1)",
    name: "wheat-svgrepo-com (1).svg",
    src: "/sticker/wheat-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "liquid-svgrepo-com",
    name: "liquid-svgrepo-com.svg",
    src: "/sticker/liquid-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "tree-2-svgrepo-com",
    name: "tree-2-svgrepo-com.svg",
    src: "/sticker/tree-2-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "helmet-svgrepo-com",
    name: "helmet-svgrepo-com.svg",
    src: "/sticker/helmet-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Sports",
  },
  {
    id: "scales-justice-lawyer-svgrepo-com",
    name: "scales-justice-lawyer-svgrepo-com.svg",
    src: "/sticker/scales-justice-lawyer-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "delphi02-svgrepo-com",
    name: "delphi02-svgrepo-com.svg",
    src: "/sticker/delphi02-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "operation-svgrepo-com",
    name: "operation-svgrepo-com.svg",
    src: "/sticker/operation-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Work",
  },
  {
    id: "bonfire-flame-svgrepo-com (1)",
    name: "bonfire-flame-svgrepo-com (1).svg",
    src: "/sticker/bonfire-flame-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "plague-doctor-profile-svgrepo-com",
    name: "plague-doctor-profile-svgrepo-com.svg",
    src: "/sticker/plague-doctor-profile-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Work",
  },
  {
    id: "crystal-shard-svgrepo-com (1)",
    name: "crystal-shard-svgrepo-com (1).svg",
    src: "/sticker/crystal-shard-svgrepo-com (1).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "sun-svgrepo-com (2)",
    name: "sun-svgrepo-com (2).svg",
    src: "/sticker/sun-svgrepo-com (2).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "glasses-svgrepo-com",
    name: "glasses-svgrepo-com.svg",
    src: "/sticker/glasses-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "halloween-horror-owl-svgrepo-com",
    name: "halloween-horror-owl-svgrepo-com.svg",
    src: "/sticker/halloween-horror-owl-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "crystal-shard-svgrepo-com",
    name: "crystal-shard-svgrepo-com.svg",
    src: "/sticker/crystal-shard-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "music-svgrepo-com (5)",
    name: "music-svgrepo-com (5).svg",
    src: "/sticker/music-svgrepo-com (5).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Art",
  },
  {
    id: "heart-mother-svgrepo-com",
    name: "heart-mother-svgrepo-com.svg",
    src: "/sticker/heart-mother-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Love",
  },
  {
    id: "christmas-cold-snowflake-svgrepo-com",
    name: "christmas-cold-snowflake-svgrepo-com.svg",
    src: "/sticker/christmas-cold-snowflake-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Celebration",
  },
  {
    id: "crown-svgrepo-com (3)",
    name: "crown-svgrepo-com (3).svg",
    src: "/sticker/crown-svgrepo-com (3).svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Miscellaneous",
  },
  {
    id: "leaf-organic-flora-svgrepo-com",
    name: "leaf-organic-flora-svgrepo-com.svg",
    src: "/sticker/leaf-organic-flora-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Nature",
  },
  {
    id: "pineapple-svgrepo-com",
    name: "pineapple-svgrepo-com.svg",
    src: "/sticker/pineapple-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "semitic-neopaganism-religion-svgrepo-com",
    name: "semitic-neopaganism-religion-svgrepo-com.svg",
    src: "/sticker/semitic-neopaganism-religion-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Religion",
  },
  {
    id: "birthday-cake-svgrepo-com",
    name: "birthday-cake-svgrepo-com.svg",
    src: "/sticker/birthday-cake-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Food",
  },
  {
    id: "destructive-magic-svgrepo-com",
    name: "destructive-magic-svgrepo-com.svg",
    src: "/sticker/destructive-magic-svgrepo-com.svg",
    category: "sticker",
    type: "svg_file",
    subcategory: "Fantasy",
  },
  {
    id: "brush",
    name: "brush",
    src: "/frames/brush.svg",
    category: "border",
    subcategory: "Shapes",
    type: "svg_file",
  },
  {
    id: "round_square",
    name: "round_square",
    src: "/frames/round_square.svg",
    category: "border",
    subcategory: "Shapes",
    type: "svg_file",
  },
  {
    id: "delineado",
    name: "delineado",
    src: "/frames/delineado.svg",
    category: "border",
    subcategory: "Shapes",
    type: "svg_file",
  },
  {
    id: "estrelinha",
    name: "estrelinha",
    src: "/frames/estrelinha.svg",
    category: "border",
    subcategory: "Shapes",
    type: "svg_file",
  },
  {
    id: "baralho",
    name: "baralho",
    src: "/frames/baralho.svg",
    category: "border",
    subcategory: "Shapes",
    type: "svg_file",
  },
];
