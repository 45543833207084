import { ShoppingCartOutlined } from "@mui/icons-material";
import { Badge } from "@mui/joy";
import { useEffect, useState } from "react";

import { Link } from "react-router-dom";

export default function ShoppingCart(props: {
    className?: string,
    iconColor?: string,
    productQuantity?: number
}) {
    const [cartItem, setCartItem] = useState(0);

    useEffect(() => {
        setCartItem(props.productQuantity)
    }, [cartItem, props.productQuantity])

    return (
        <Link to="/carrinho" className={props.className}>
            <Badge variant="solid" color="secondary" badgeContent={cartItem}>
                <ShoppingCartOutlined sx={{ color: props.iconColor }} />
            </Badge>
        </Link>
    )
}
