import { Input } from "@mui/joy";
import { useState } from "react";
import { SketchPicker } from "react-color"

export default function ColorPicker(props:{
    value: string,
    onChangeComplete: (color:any)=>void
}) {

    const [isSelectingColor, setSelectingColor] = useState(false)
    const [selectedColor, setSelectedColor] = useState(props.value)

    return (
        <>
            <Input type="color" value={selectedColor} onClick={(evt) => {
                evt.preventDefault();
                setSelectingColor(true)
            }} />
            {isSelectingColor && <div style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                zIndex: 100,
            }} onMouseLeave={()=>setSelectingColor(false)}><SketchPicker color={selectedColor} onChangeComplete={(color) => {
                setSelectedColor(color.hex)
                props.onChangeComplete(color.hex)
            }} /></div>}
        </>

    )
}