import { Box, Button, CircularProgress, Divider, Grid, Stack, Typography } from "@mui/joy";
import { useNavigate, useParams } from "react-router-dom";
import { initMercadoPago } from "@mercadopago/sdk-react";
import { useEffect, useState } from "react";
import OrderInterface from "../../interfaces/orderInterface";
import { getOrderById } from "../../services/orderService";
import PageHeader from "../../components/global_components/PageHeader";
import OrderItemPreview from "../../components/global_components/OrderItemPreview";
import ProcessingOrderComponent from "./processingComponent";
import PayedComponent from "./payedComponent";
import CancelledComponent from "./cancelledComponent";

export default function Order() {
	const { orderID } = useParams();
	const [orderData, setOrderData] = useState<OrderInterface>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const nav = useNavigate();
	const mlPublicKey = process.env.REACT_APP_PUBLIC_KEY_MP_CHECKOUT_PRO;

	useEffect(() => {
		setLoading(true);
		getOrderById(orderID)
			.then((x) => {
				console.log(x);
				setOrderData(x);
			})
			.catch((err) => {})
			.finally(() => setLoading(false));
		console.log(mlPublicKey);
		initMercadoPago(mlPublicKey);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<Stack spacing={2} margin={{ md: "2em", xs: "0.5em 0.5em" }} maxWidth={1300} width="100vw">
			{orderData && (
				<Stack width="100%">
					<PageHeader title="Já recebemos seu Pedido!" />
					<Grid container columns={{ xs: 5.5, md: 12 }}>
						<Grid xs={5.5}>
							<Typography level="body-md" textAlign="center" marginBottom="2em">
								Detalhes do pedido
							</Typography>
							<OrderItemPreview order={orderData} />
						</Grid>
						{window.innerWidth > 768 && (
							<Grid xs={1} height="100%" justifyContent="center" alignItems="center" display="flex">
								<Divider orientation="vertical" sx={{ height: "100%" }} />
							</Grid>
						)}
						<Grid xs={5.5}>
							{orderData?.orderStatus === "processing" && <ProcessingOrderComponent order={orderData} />}
							{orderData?.orderStatus === "payment_received" && <PayedComponent order={orderData} />}
							{orderData?.orderStatus === "cancelled" && <CancelledComponent order={orderData} />}
						</Grid>
					</Grid>
				</Stack>
			)}
			<Stack justifyContent="center" direction="row">
				{loading && <CircularProgress />}
				{!loading && !orderData && (
					<Stack>
						<Typography level="h2">Não foi possível carregar dados desse pedido</Typography>

						<Box sx={{ display: "flex", gap: "1em", justifyContent: "center" }}>
							<Button color="primary" onClick={() => nav("/minha-pagina")} size="lg">
								Voltar a minha página
							</Button>
						</Box>
					</Stack>
				)}
			</Stack>
		</Stack>
	);
}
