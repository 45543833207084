import { createContext, useEffect, useState } from "react";

export const ErrorsContext = createContext<{
	isValid: boolean;
	addError: (errorInterface) => void;
	removeError: (string) => void;
}>(null);

export interface errorInterface {
	id: string;
	error: string;
	timeout?: number;
}

export default function Errors({ children }) {
	const [isValid, setIsValid] = useState(true);
	const [errors, setErrors] = useState<{}>([]);

	useEffect(() => {
		if (Object.keys(errors).length > 0) {
			setIsValid(false);
			console.info("Erro na validação", errors);
		} else {
			setIsValid(true);
			console.info("Sucesso");
		}
	}, [errors]);

	const addError = (error: errorInterface) => {
		errors[error.id] = {
			...error,
		};
		setErrors({ ...errors });
	};

	const removeError = (errorId: string) => {
		delete errors[errorId];
		setErrors({ ...errors });
	};

	return (
		<ErrorsContext.Provider
			value={{
				isValid,
				addError,
				removeError,
			}}
		>
			{children}
		</ErrorsContext.Provider>
	);
}
