import apiClient from "../plugins/apiClient";

import CartInterface from "../interfaces/cartInterface";

export async function getCart() {
	try {
		const response = await apiClient.get(`/customer/@userID/cart`);
		return response.data;
	} catch (error) {

		throw error
	}
}

export async function newCart(cart: CartInterface) {
	try {
		const response = await apiClient.post(`/customer/@userID/cart`, cart);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error
	}
}

export async function deleteCart() {
	try {
		const response = await apiClient.delete(`/customer/@userID/cart`);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}
