import apiClient from "../plugins/apiClient";

export enum Effects {
    REMOVE_BACKGROUND = "REMOVE_BACKGROUND",
    CARTOONIFY = "CARTOONIFY"
}


export interface ImageEffects {
    file: string,
    effect: Array<Effects>
}


export async function postImageEffect(effect: ImageEffects){
    try {
        return (await apiClient.post("/v0/image/effect", effect)).data
    } catch (error) {
        console.error(error)
    }
}