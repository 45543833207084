import { fetchAuthSession } from "aws-amplify/auth";
import { Media } from "../interfaces/mediaInterface";
import apiClient from "../plugins/apiClient";

export async function newMedia(projectId: string, media: Media) {
    const info = await fetchAuthSession();
    if(info.userSub == null) {
        return localMediaPost(media);
    }
    try {
        const response = await apiClient.post( `/customer/@userID/project/${projectId}/media`, media);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

function localMediaPost(media: Media){

}