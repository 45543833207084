import { Typography, Box } from "@mui/joy";
import Lottie from "lottie-react";
import OrderInterface from "../../interfaces/orderInterface";
import checkLottie from "../../lotties/check.json";
import { useRef } from "react";

export default function PayedComponent(props: { order: OrderInterface }) {
	return (
		<Box alignItems="center" display={"flex"} flexDirection={"column"}>
			<Typography level="title-lg" textAlign="center">
				Seu pagamento foi recebido e o pedido já seguiu para produção
			</Typography>
			<Lottie
				animationData={checkLottie}
				loop={false}
				style={{ width: 300 }}
				autoplay={true}
			/>
		</Box>
	);
}
