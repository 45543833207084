import { Chip, CircularProgress, Grid, Radio, RadioGroup, Stack } from "@mui/joy";
import CheckIcon from "@mui/icons-material/Check";
import { useContext, useEffect, useState } from "react";
import { ElementsContext } from "../../../../pages/create/create";
import Element from "../../../../interfaces/element";
import { v1 as uuidV1 } from "uuid";
import { ArtInterface } from "../../../../interfaces/artInterface";
import { stickers } from "./stickers";
import { subcat } from "./categories";
import ChangeSetSingleton from "../../texture_renderer/ChangeSetSingleton";

export default function StickerMenu() {
	const { elements, setElements } = useContext(ElementsContext);

	const [selected, setSelected] = useState<string>("Shapes");
	const stickerList: ArtInterface[] = stickers;
	const [stickerData, setBorderData] = useState<ArtInterface[]>(stickerList);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	async function loadSticker(sticker: ArtInterface): Promise<ArtInterface> {
		const rnd = (Math.random() + 1).toString(36).substring(7);
		let newSticker = { ...sticker };
		newSticker.id = sticker.name + rnd;
		if (!newSticker.data) {
			if (!newSticker.src.includes("http")) {
				newSticker.src = window.location.origin + newSticker.src;
			}
			const result = await fetch(newSticker.src)
				.then(async function (response) {
					let text = await response.text();
					newSticker.data = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(text);
					newSticker.colored = newSticker.data;
					return newSticker;
				})
				.catch((err) => {
					console.error(err);
					return null;
				});
			return result;
		} else {
			newSticker.colored = newSticker.data;
			return newSticker;
		}
	}

	useEffect(() => {
		setIsLoading(true);
		Promise.all(stickerList.filter((x) => x.subcategory === selected).map((x) => loadSticker(x)))
			.then((x) => {
				setBorderData(x);
			})
			.finally(() => {
				setIsLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected]);

	function handleBorderClick(data: string) {
		const newElement: Element = {
			id: uuidV1(),
			media: {
				src: data,
			},
			x: 300,
			y: 300,
			selected: true,
			type: "sticker",
			zIndex: elements.length + 1,
			scaleX: 0.5,
			scaleY: 0.5,
			shown: true,
			rotation: 0,
		};

		setElements((els) => {
			ChangeSetSingleton.get().addHistory([newElement, ...els]);
			return [newElement, ...els];
		});
	}

	return (
		<Stack className="stickers" spacing={2} sx={{ height: "100%", overflowY: "auto" }}>
			<RadioGroup
				orientation="horizontal"
				sx={{
					flexWrap: "wrap",
					gap: 0.5,
					marginTop: "0.5em",
					justifyContent: "space-between",
				}}
			>
				{subcat.map((sub) => {
					const checked = selected.includes(sub.value);
					return (
						<Chip
							key={sub.value}
							sx={{ margin: "0.2em" }}
							variant="plain"
							color={checked ? "primary" : "neutral"}
							startDecorator={checked && <CheckIcon sx={{ zIndex: 1, pointerEvents: "none" }} />}
						>
							<Radio
								variant="outlined"
								color={checked ? "primary" : "neutral"}
								disableIcon
								overlay
								label={sub.name}
								checked={checked}
								onChange={(event) => {
									if (event.target.checked) {
										setSelected(sub.value);
									}
								}}
							/>
						</Chip>
					);
				})}
			</RadioGroup>
			<Grid
				className="stickers"
				spacing={2}
				container
				justifyContent={"center"}
				sx={{ minHeight: "40vh", marginTop: "5.5em" }}
			>
				{isLoading && <CircularProgress />}
				{stickerData
					.filter((x) => {
						if (!x.data) {
							return false;
						}
						if (selected !== x.subcategory) {
							return false;
						}
						return true;
					})
					.map((x) => (
						<Grid xs={4} key={x.id} sm={4}>
							<img key={x.id} src={x.colored} width="100%" onClick={() => handleBorderClick(x.colored)} alt={x.name} />
						</Grid>
					))}
			</Grid>
		</Stack>
	);
}
