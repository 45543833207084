import Customer from "../interfaces/customer";
import apiClient from "../plugins/apiClient";

export async function getShipment(customerId: Customer['customerId']) {
    try {
        const response = await apiClient.get(`/customer/${customerId}/shipment`)
        return response.data
    } catch(error) {
        throw error
    }
}