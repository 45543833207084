import Order from "../interfaces/orderInterface"
import apiClient from "../plugins/apiClient"

export async function makeOrder(payload) {
    try {
        const response = await apiClient.post(`/customer/@userID/order`, payload)
        return response.data as Order
    } catch(error) {
        console.error(error)
        throw error
    }
}
 
export async function getOrderById(orderId: Order["orderId"]) {
    try {
        const response = await apiClient.get(`/customer/@userID/order/${orderId}`)
        return response.data as Order
    } catch(error) {
        console.error(error)
        throw error
    }
}
 
export async function getOrders() {
    try {
        const response = await apiClient.get(`/customer/@userID/order`)
        return response.data as Order[]
    } catch(error) {
        console.error(error)
        throw error
    }
}