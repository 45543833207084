import './CardInfo.scss';

import { Box, Button, Card, CardActions, CardContent, IconButton, Tooltip, Typography } from "@mui/joy";
import { useEffect } from 'react';
import { CheckCircleOutline, RadioButtonUnchecked } from '@mui/icons-material';

export default function CardInfo(props: {
    cardId: number,
    cardTitle?: string,
    cardImg?: string,
    checked?: boolean,
    cardCheckedIcon?: boolean,
    cardActions: boolean,
    firstBtnTitle?: string,
    secondBtnTitle?: string,
    isIconButton?: boolean,
    haveSecondBtn?: boolean,
    firstIcon?: any,
    firstIconTitle?: string,
    firstIconColor?: "primary" | "secondary" | "danger" | "warning" | "success" | "neutral",
    firstIconTooltipColor?: "primary" | "secondary" | "danger" | "warning" | "success" | "neutral",
    firstIconVariant?: "outlined" | "soft" | "plain" | "solid",
    firstIconTooltipVariant?: "outlined" | "soft" | "plain" | "solid",
    secondIcon?: any,
    secondIconTitle?: string,
    secondIconColor?: "primary" | "secondary" | "danger" | "warning" | "success" | "neutral",
    secondIconTooltipColor?: "primary" | "secondary" | "danger" | "warning" | "success" | "neutral",
    secondIconVariant?: "outlined" | "soft" | "plain" | "solid",
    secondIconTooltipVariant?: "outlined" | "soft" | "plain" | "solid",
    handleFirstBtn?: () => void,
    handleSecondBtn?: () => void,
    handleFirstIcon?: () => void,
    handleSecondIcon?: () => void,
    handleCheckedCard: (cardId: number) => void,
    children: any,
}) {


    useEffect(() => {
    }, [props.checked])

    return (
        <Card className={`cards ${props.checked ? "active" : ""}`} onClick={() => { props.handleCheckedCard(props.cardId) }} key={props.cardId}>
            <CardContent className="cardContent">
                {
                    props.cardTitle &&
                    <CardContent className="cardTitle" >
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5em' }}>
                            {
                                props.cardImg &&
                                <img src={props.cardImg} style={{ width: '150px' }} alt='card preview'/>
                            }
                            <Typography level='body-md'>{props.cardTitle}</Typography>
                        </Box>
                    </CardContent>
                }
                <CardContent className="cardBody">
                    {
                        props.cardCheckedIcon &&
                        <>
                            {
                                props.checked &&
                                <CheckCircleOutline color='primary' />
                            }
                            {
                                !props.checked &&
                                <RadioButtonUnchecked color='disabled' />
                            }
                        </>
                    }
                    {props.children}
                </CardContent>
            </CardContent>
            {
                props.cardActions &&
                <CardActions>
                    {
                        props.isIconButton !== true &&
                        <Box>
                            <Button onClick={() => props.handleFirstBtn}>{props.firstBtnTitle}</Button>
                            {
                                props.haveSecondBtn &&
                                <Button onClick={() => props.handleSecondBtn}>{props.secondBtnTitle}</Button>
                            }
                        </Box>
                    }
                    {
                        props.isIconButton &&
                        <Box>
                            <Tooltip title={props.firstIconTitle} size="sm" color={props.firstIconTooltipColor} variant={props.firstIconTooltipVariant}>
                                <IconButton onClick={() => props.handleFirstIcon} color={props.firstIconColor} variant={props.firstIconVariant}>
                                    {props.firstIcon}
                                </IconButton>
                            </Tooltip>
                            {
                                props.haveSecondBtn &&
                                <Tooltip title={props.secondIconTitle} size="sm" color={props.secondIconTooltipColor} variant={props.secondIconTooltipVariant}>
                                    <IconButton onClick={() => props.handleSecondIcon} color={props.secondIconColor} variant={props.secondIconVariant}>
                                        {props.secondIcon}
                                    </IconButton>
                                </Tooltip>
                            }
                        </Box>
                    }
                </CardActions>
            }
        </Card>
    )
}