import { Box, Tooltip, Typography } from "@mui/joy";

export default function TitleMenu(props: { title: string; handleEditTitle: () => void }) {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexGrow: "1",
				backgroundColor: "white",
			}}
		>
			<Tooltip title="Editar título" variant="outlined" color="primary">
				<Typography level="h2" sx={{ textAlign: "center", cursor: "pointer" }} onClick={() => props.handleEditTitle()}>
					{props.title || "Nomeie sua caneca"}
				</Typography>
			</Tooltip>
		</Box>
	);
}
