import React, { createContext, useContext, useEffect, useState } from "react";
import { fetchUserAttributes, fetchAuthSession } from "aws-amplify/auth";
import { getCustomerByID, newCustomer } from "../services/customerService";
import Customer from "../interfaces/customer";
import { AuthSession } from "@aws-amplify/core/dist/esm/singleton/Auth/types";

const UserContext = createContext<{
	user: Customer;
	setUser: (any) => void;
	accessToken: string;
	setAccessToken: (any) => void;
	userLoading: boolean;
}>(null);

export function UserProvider({ children }) {
	const [user, setUser] = useState(null);
	const [userLoading, setUserLoading] = useState<boolean>(true);
	const [accessToken, setAccessToken] = useState("");

	async function userToken() {
		try {
			const { accessToken } = ((await fetchAuthSession()) as AuthSession).tokens ?? {};
			let token = accessToken?.toString();

			setAccessToken(token ?? "");
			sessionStorage.setItem("userToken", token);

			return token;
		} catch (err) {
			console.error(err);
		}
	}

	async function defineUser(userAttributes) {
		try {
			console.log(userAttributes)
			let response = await getCustomerByID(userAttributes.sub);
			setUser(response);
		} catch (error) {
			if (error.response.status === 404) {
				const customer: Customer = {
					name: userAttributes.name,
					email: userAttributes.email,
				};
				newCustomer(customer)
					.then((x) => {
						setUser(x);
					})
					.catch((err) => console.error("Erro ao cadastrar usuário", err));
			}
		}
	}

	useEffect(() => {
		setUserLoading(true);
		fetchUserAttributes()
			.then(async (attr) => {
				sessionStorage.setItem("userID", attr.sub);
				await defineUser(attr);
			})
			.catch((err) => {
				console.error("Usuário não logado!");
			})
			.finally(() => setUserLoading(false));

		userToken();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<UserContext.Provider value={{ user, setUser, accessToken, setAccessToken, userLoading }}>
			{children}
		</UserContext.Provider>
	);
}
export function useUser() {
	return useContext(UserContext);
}
