import ImageMenu from "./img_menu/img_menu";
import TextMenu from "./text_menu/text_menu";
import EmojiMenu from "./emoji_menu/emoji_menu";
import StickerMenu from "./sticker_menu/sticker_menu";

import "./object_menu.scss";

import { Stack, Divider, Box, Tabs, Tab, TabList, TabPanel, Tooltip, IconButton } from "@mui/joy";

import {
	AddPhotoAlternate,
	AddReaction,
	ArrowDropDown,
	Close,
	Fullscreen,
	FullscreenExit,
	Menu,
	TextFields,
} from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import Tutorial from "../../global_components/tutorial";
import Element from "../../../interfaces/element";
import { ElementsContext } from "../../../pages/create/create";
import TitleMenu from "./title_menu/title_menu";
import SaveMenu from "./save_menu/save_menu";

export default function ObjectMenu(props: {
	mugTitle?: string;
	mugDescription?: string;
	bgImage?: any;
	index?: number | null;
	projectId: string;
	isFullscreen: boolean;
	handleStep: (value: number | null) => void;
	handleEditTitle: () => void;
	handleSaveProject: (toCart: boolean) => void;
	handleDownloadProject: () => void;
	handleMenuLoading: (value: [boolean, string]) => void;
	handleFullscreen: () => void;
}) {
	const [title, setTitle] = useState(props.mugTitle ?? "Minha Caneca");
	const [, setDescription] = useState(props.mugDescription ?? "");
	const [activeMenu, setActiveMenu] = useState(props.index ?? "-1");
	const [elementToEdit, setElementToEdit] = useState<Element | null>(null);
	const { elements } = useContext(ElementsContext);
	const [isMobile, setIsMobile] = useState(false);

	const handleResize = () => {
		setIsMobile(window.innerWidth <= 768 ? true : false);
	};

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		setTitle(props.mugTitle);
		setDescription(props.mugDescription);
		if (props.index === 3 && isMobile) {
			setActiveMenu(4);
		} else if (props.index !== null && props.index !== 3 && isMobile) {
			setActiveMenu(null);
		}
	}, [props]);

	useEffect(() => {
		const selected = elements.filter((x) => x.selected);
		if (selected.length === 1) {
			setElementToEdit(selected[0]);
			switch (selected[0].type) {
				case "text":
					setActiveMenu(1);
					break;
				default:
					if (activeMenu === 1) setActiveMenu(null);
					break;
			}
		} else {
			if (activeMenu === 1) setActiveMenu(null);
			setElementToEdit(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [elements]);

	const getStackStyle = () => {
		if (!isMobile) {
			return {
				height: "100vh",
				maxHeight: "100vh",
				width: "30vw",
				borderRight: "1px solid var(--joy-palette-neutral-200)",
			};
		} else {
			return {
				height: "10dvh",
				maxHeight: "10dvh",
				width: "100vw",
				borderRight: "1px solid var(--joy-palette-neutral-200)",
			};
		}
	};

	return (
		<Stack sx={getStackStyle()} divider={<Divider />}>
			{!isMobile && <TitleMenu title={title} handleEditTitle={props.handleEditTitle} />}
			<Tabs
				sx={{ height: isMobile ? "8dvh" : "80vh", flexDirection: isMobile ? "column-reverse" : "row" }}
				defaultValue={0}
				onChange={(value, v) => [console.log(v), setActiveMenu(v)]}
				value={activeMenu}
				orientation={isMobile ? "horizontal" : "vertical"}
			>
				<Tutorial
					tutorialText={
						"Aqui você pode navegar entre as abas do editor para personalizar sua caneca!\nNa aba 'Texto', por exemplo, você pode adicionar qualquer texto que escrever, assim como selecionar a fonte e cor do seu texto. "
					}
					open={props.index === 0}
					handleSteps={props.handleStep}
					showPreviousBtn={false}
					direction={isMobile ? "top" : "right"}
					complete={25}
				>
					<TabList
						id="tabList"
						sx={{ backgroundColor: "var(--joy-palette-neutral-100)", justifyContent: "space-between" }}
					>
						<Box
							display="flex"
							gap="0.5em"
							width="100%"
							flexDirection={isMobile ? "row" : "column"}
							justifyContent={isMobile ? "space-evenly" : "flex-start"}
						>
							<Tooltip title="Imagem" placement="right" arrow variant="plain" color="primary">
								<Tab className="tab" color="primary" indicatorPlacement={isMobile ? "top" : "right"}>
									<AddPhotoAlternate />
								</Tab>
							</Tooltip>
							<Tooltip title="Texto" placement="right" arrow variant="plain" color="primary">
								<Tab className="tab" color="primary" indicatorPlacement={isMobile ? "top" : "right"}>
									<TextFields />
								</Tab>
							</Tooltip>
							{isMobile && (
								<Tooltip title="Texto" placement="right" arrow variant="plain" color="primary">
									<Tab className="tab" color="primary" indicatorPlacement={isMobile ? "top" : "right"}>
										<Menu />
									</Tab>
								</Tooltip>
							)}
							<Tooltip title="Emoji" placement="right" arrow variant="plain" color="primary">
								<Tab className="tab" color="primary" indicatorPlacement={isMobile ? "top" : "right"}>
									<AddReaction fontSize="large" />
								</Tab>
							</Tooltip>
							<Tooltip title="Figurinhas" placement="right" arrow variant="plain" color="primary">
								<Tab className="tab" color="primary" indicatorPlacement={isMobile ? "top" : "right"}>
									<span className="material-symbols-rounded">note_stack_add</span>
								</Tab>
							</Tooltip>
						</Box>

						{!isMobile && (
							<Box className="fullscreen" onClick={props.handleFullscreen}>
								{props.isFullscreen === false && (
									<Tooltip title="Tela cheia(F11)" placement="right" arrow variant="plain" color="primary">
										<Fullscreen color="primary" sx={{ width: "2em", height: "2em" }} />
									</Tooltip>
								)}
								{props.isFullscreen === true && (
									<Tooltip title="Sair da tela cheia (Esc)" placement="right" arrow variant="plain" color="primary">
										<FullscreenExit color="primary" sx={{ width: "2em", height: "2em" }} />
									</Tooltip>
								)}
							</Box>
						)}
					</TabList>
				</Tutorial>
				<TabPanel className="tabPanel" value={0}>
					<IconButton onClick={() => setActiveMenu(null)}>
						<Close />
					</IconButton>
					<br />
					<ImageMenu handleMenuLoading={props.handleMenuLoading} />
				</TabPanel>
				<TabPanel className="tabPanel" value={1}>
					<IconButton onClick={() => setActiveMenu(null)}>
						<Close />
					</IconButton>
					<br />
					<br />
					<TextMenu textToEdit={elementToEdit?.type === "text" ? elementToEdit : null} key={elementToEdit?.id} />
				</TabPanel>
				<TabPanel className="tabPanel" value={2}>
					<IconButton onClick={() => setActiveMenu(null)}>
						<Close />
					</IconButton>
					<EmojiMenu />
				</TabPanel>
				<TabPanel className="tabPanel" value={3}>
					<IconButton onClick={() => setActiveMenu(null)}>
						<Close />
					</IconButton>
					<br />
					<br />
					<StickerMenu />
				</TabPanel>
				{isMobile && (
					<TabPanel className="tabPanel" value={4}>
						<IconButton onClick={() => setActiveMenu(null)}>
							<Close />
						</IconButton>
						<TitleMenu title={title} handleEditTitle={props.handleEditTitle} />
						<SaveMenu
							handleDownloadProject={props.handleDownloadProject}
							handleSaveProject={props.handleSaveProject}
							handleStep={props.handleStep}
							index={props.index}
						/>
					</TabPanel>
				)}
			</Tabs>

			{!isMobile && (
				<SaveMenu
					handleDownloadProject={props.handleDownloadProject}
					handleSaveProject={props.handleSaveProject}
					handleStep={props.handleStep}
					index={props.index}
				/>
			)}
		</Stack>
	);
}
