import mug from "../../assets/mug.png";
import mugs from "../../assets/mugs.png";
import cattoMug from "../../assets/cattoMug.png";
import backVideo from "../../assets/backVideo.mp4";
import mugVideo from "../../assets/mugCreationVideo.mp4";
import fizzLogo from "../../assets/logozz-color-white.png";

import Lottie from "lottie-react";
import supportData from "../../lotties/support.json";
import coWorkData from "../../lotties/co_workers.json";
import creativityData from "../../lotties/creativity.json";

import { Box, Button, Grid, Stack, Tooltip, Typography } from "@mui/joy";
import { Helmet } from "react-helmet";

import "./landing.scss";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import LoginMenu from "../../components/login_menu/LoginMenu";
import MobileDrawerMenu from "../../components/login_menu/MobileDrawerMenu";
import { useUser } from "../../providers/UserInfo";
import { Email, LinkedIn, WhatsApp } from "@mui/icons-material";

function Landing() {
	const [productIndex, setProductIndex] = useState(0);
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const [continueProject, setContinueProject] = useState(JSON.parse(sessionStorage.getItem("localProject")));

	// const carouselOptions: EmblaOptionsType = { loop: true };
	// const test = 5;
	// const carouselSlides = Array.from(Array(test).keys());

	const user = useUser();
	const nav = useNavigate();
	const myRef = useRef(null);

	const handleResize = () => {
		setIsMobile(window.innerWidth <= 768 ? true : false);
	};

	useEffect(() => {
		handleResize();
		setContinueProject(JSON.parse(sessionStorage.getItem("localProject")));

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	function handleTopMenu() {
		if (isMobile) {
			return (
				<Box className="headerTopBarMobile">
					<Link to="/" className="logoTop" style={{ margin: "0", height: "2em" }}>
						<img src={fizzLogo} style={{ height: "2em" }} alt="Fizz logo" />
					</Link>
					{!user.user && (
						<>
							<Link className="menuLink" to="/login">
								Entrar
							</Link>
							<Button size="lg" onClick={() => nav("/login")}>
								Cadastre-se
							</Button>
						</>
					)}
					{user.user && <MobileDrawerMenu color="primary" variant="solid" />}
				</Box>
			);
		}
		if (!isMobile) {
			return (
				<div className="headerTopBar">
					<div className="topWrapper">
						<Link to="/" className="logoTop">
							<img src={fizzLogo} style={{ height: "64px" }} alt="fizz logo" />
						</Link>
						<Box display="flex" gap="1em" justifyContent="flex-end" alignContent="flex-end">
							<Link className="menuLink" to={continueProject ? "/criar/convidado" : "/criar"}>
								{continueProject ? "Continuar" : "Criar"}
							</Link>
							{/* <Link className="menuLink" to="/explorar"> Explorar </Link> */}
							{!user.user && (
								<Link className="menuLink" to="/login">
									Entrar
								</Link>
							)}
							{user.user && (
								<Link className="menuLink" to="/colecao">
									Coleção
								</Link>
							)}
							{!user.user && (
								<Button onClick={() => nav("/login")} sx={{ width: "auto" }}>
									Cadastre-se
								</Button>
							)}
							{user.user && <LoginMenu color="primary" variant="solid" />}
						</Box>
					</div>
				</div>
			);
		}
	}

	useEffect(() => {
		setTimeout(() => {
			if (productIndex < 2) {
				setProductIndex(productIndex + 1);
			} else {
				setProductIndex(0);
			}
		}, 3000);
	}, [setProductIndex, productIndex]);

	return (
		<div className="snapWrapper">
			<Helmet>
				<title>Fizz Online - Canecas Personalizadas</title>
			</Helmet>
			<header className="landingHeader snapChild">
				<video id="backgroundVideo" loop autoPlay muted disablePictureInPicture playsInline>
					<source src={backVideo} type="video/mp4" />
				</video>
				{handleTopMenu()}
				<div className="homeWrapper">
					<Grid container spacing={2} padding="2em">
						<Grid xs={12}>
							<div className="headerContent">
								<h2 className="headText">Editor de alta fidelidade para suas canecas personalizadas</h2>
								<ul className="productImage">
									<li>
										<img src={mug} alt="section" className={productIndex === 0 ? "show" : ""}></img>
									</li>
									<li>
										<img src={cattoMug} alt="section" className={productIndex === 1 ? "show" : ""}></img>
									</li>
									<li>
										<img src={mugs} alt="section" className={productIndex === 2 ? "show" : ""}></img>
									</li>
								</ul>
								<Button
									onClick={() => nav(continueProject ? "/criar/convidado" : "/criar")}
									sx={{ width: "auto", gridArea: "buttons", fontSize: "1.5em" }}
									size="xl"
								>
									{continueProject ? "Continuar personalização" : "Começar personalização"}
								</Button>
								<a
									href="/#"
									onClick={() => myRef.current.scrollIntoView({ block: "center", behavior: "smooth" })}
									style={{ gridArea: "saiba", textAlign: "end", cursor: "pointer" }}
								>
									Saiba mais
								</a>
							</div>
						</Grid>
					</Grid>
				</div>
			</header>

			<main className="snapChild" id="main">
				<Stack>
					<div className="homeWrapper" style={{ backgroundColor: "var(--primary)" }}>
						<Grid container padding="2em" ref={myRef} spacing={{ xs: 2, md: 0 }}>
							<Grid md={4} xs={12} container>
								<Grid md={12} xs={12} justifyContent={{ xs: "center", md: "flex-start" }}>
									<Typography level="h2" textColor="white" marginBottom="0.5em">
										Seja você mesmo
									</Typography>
									<Typography level="title-md" textColor="white">
										Use o nosso editor para criar canecas que sejam do seu jeito!
										<br />
										Adicione imagens, textos e até figurinhas. O limite é sua imaginação!
									</Typography>
								</Grid>
								<Grid md={12} xs={12}>
									<Lottie animationData={creativityData} width={"100%"} controls={false} />
								</Grid>
							</Grid>
							<Grid md={1} />
							<Grid md={7} xs={12} sx={{ display: "flex", alignItems: "center" }}>
								<Box className="editorVideo">
									<video
										loop
										autoPlay
										muted
										disablePictureInPicture
										playsInline
										style={{ maxWidth: "100%", borderRadius: "0.5rem" }}
										onClick={() => nav("/criar")}
									>
										<source src={mugVideo} type="video/mp4" />
									</video>
								</Box>
							</Grid>
							<Grid md={1} />
						</Grid>
					</div>
					<div className="homeWrapper">
						<Grid container padding="6em 2em" spacing={{ xs: 2, md: 0 }}>
							<Grid container md={5} xs={12} gap={"2em"}>
								<Grid md={12} xs={12} display={"flex"} flexDirection={"column"} gap={"1em"}>
									<Typography level="h2">Sobre nós</Typography>
									<Typography level="title-md">
										Somos uma empresa brasileira com o objetivo de levar agilidade e originalidade na confecção de
										produtos personalizados
									</Typography>
									<Typography level="title-md">
										Localizada em São Paulo, a selva de pedra, queremos trazer mais cor para o mundo, eternizar os bons
										momentos da vida e demonstrar o quanto amamos nossa arte
									</Typography>
								</Grid>
								<Grid md={12} xs={12} display={"flex"} gap={"1em"}>
									<LinkedIn
										sx={{ cursor: "pointer", color: "black", scale: "2", paddingLeft: "4px" }}
										onClick={() => (window.location.href = "https://www.linkedin.com/company/fizzonline")}
									/>
								</Grid>
							</Grid>
							<Grid md={2} xs={12} />
							<Grid container md={5} xs={12}>
								<Grid md={12} xs={12} display={"flex"} flexDirection={"column"} gap={"1em"}>
									<Typography level="h2">Sobre o produto</Typography>
									<Typography level="title-md">
										O editor Fizz permite que você personalize sua caneca do jeito que você quiser, de uma maneira
										rápida e intuitiva!
									</Typography>
									<Typography level="title-md">
										A caneca é elegante e resistente, ideal para o dia a dia. Feita de porcelana de alta qualidade,
										possui capacidade de 325ml e acabamento impecável. Compatível com micro-ondas e lava-louças, é
										perfeita para casa ou escritório. Um item prático e sofisticado!
									</Typography>
								</Grid>
								{/* <Grid md={12} xs={12} display={'flex'} justifyContent={'center'}>
									<img src={fizzLogo} style={{ height: "200px" }} />
								</Grid> */}
							</Grid>
							{/* <Grid md={12} xs={12} container justifyContent="center">
								<Typography level="h1" textAlign="center">
									Criado por nossa comunidade
								</Typography>
							</Grid>

							<Grid md={12} xs={0} />
							<Grid md={12} xs={12} container justifyContent="center">
								<Grid md={12} sx={{ position: "relative" }}>
									<EmblaCarousel options={carouselOptions} slides={mockData.map((item) => {
										return (
											<Box key={item.title}>
												<CardPreview
													id={item.id}
													width={isMobile ? '200px' : '300px'}
													height={isMobile ? '200px' : '300px'}
													title={item.title}
													imgSrc={cattoMug}
													showFavorite={false}
													menu={false}
												/>
											</Box>
										);
									})} />
								</Grid>
							</Grid>
							<Grid md={12} xs={12} />

							<Grid
								md={12}
								xs={12}
								sx={{
									justifyContent: "center",
									display: "flex",
									marginBottom: "1em",
									textAlign: "center",
								}}
							>
								<Typography level="h3">
									Monte sua caneca também.
									<br />É de graça e sem compromisso!
								</Typography>
							</Grid>
							<Grid md={4} xs={12} />
							<Grid md={4} xs={12} sx={{ justifyContent: "center", display: "flex" }}>
							</Grid>
							<Grid md={4} xs={12} /> */}
						</Grid>
					</div>
					<div className="homeWrapper" style={{ backgroundColor: "var(--accent)" }}>
						<Grid container padding="2em 2em 0 2em">
							<Grid md={6} xs={12} container marginBottom="1em">
								<Grid md={12} xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
									<Lottie
										animationData={(coWorkData)}
										height={300}
										width={300}
										controls={false}
										// style={{ margin: "0" }}
									/>
								</Grid>
							</Grid>
							<Grid md={6} xs={12} container>
								<Grid
									md={12}
									xs={12}
									container
									justifyContent={{ md: "flex-end", xs: "center" }}
									padding={{ md: "0", xs: "1em" }}
									marginTop="0em"
									gap={{ md: 0, xs: 2 }}
								>
									<Typography level="h2" textColor="white" textAlign={isMobile ? "center" : "justify"}>
										Produz canecas?
									</Typography>
									<Typography level="h4" textColor="white" textAlign={isMobile ? "center" : "justify"}>
										Trabalhamos com parcerias, vamos crescer juntos!
									</Typography>
									<Typography level="h4" textColor="white" textAlign={isMobile ? "center" : "end"}>
										Seja usando nosso editor para seus clientes ou produzindo canecas que nossos clientes criaram
									</Typography>
								</Grid>
								<Grid md={12} xs={12} />
								<Grid
									md={12}
									xs={12}
									container
									justifyContent={{ md: "flex-end", xs: "center" }}
									alignSelf={{ md: "flex-start" }}
									marginBottom={{ md: 0, xs: "1em" }}
								>
									<Button size="xl" onClick={() => nav("/parceria")}>
										Como funcionam as parcerias
									</Button>
								</Grid>
							</Grid>

							<Grid
								md={12}
								xs={12}
								container
								sx={{
									backgroundColor: "var(--primary)",
									padding: "2em",
									borderTopLeftRadius: "0.5rem",
									borderTopRightRadius: "0.5rem",
								}}
							>
								<Grid md={6} xs={12} container>
									<Grid md={12} xs={12}>
										<Typography level="h2" textColor="white">
											Faltou explicar algo ou quer bater um papo?
										</Typography>
										<Typography level="body-lg" textColor="white">
											Entre em contato conosco nos nossos canais de atendimento
										</Typography>
										<Stack direction="row" marginTop="1em">
											<Tooltip title="contato@fizzonline.com.br" variant="plain" color="primary">
												<LinkedIn
													color="secondary"
													sx={{ scale: "1.5", marginRight: "1em", cursor: "pointer" }}
													onClick={() => window.open("https://www.linkedin.com/company/fizzonline", "_blank")}
												/>
											</Tooltip>
											<Tooltip title="contato@fizzonline.com.br" variant="plain" color="primary">
												<Email
													color="secondary"
													sx={{ scale: "1.5", marginRight: "1em", cursor: "pointer" }}
													onClick={() => window.open("mailto:contato@fizzonline.com.br", "_blank")}
												/>
											</Tooltip>
											<Tooltip title="(11) 93458-4758" variant="plain" color="primary">
												<WhatsApp
													color="secondary"
													sx={{ scale: "1.5", marginRight: "1em", cursor: "pointer" }}
													onClick={() =>
														window.open(
															"https://wa.me/5511934584758?text=Ol%C3%A1%2C%20preciso%20de%20ajuda%20com%20o%20fizzonline.com.br",
															"_blank"
														)
													}
												/>
											</Tooltip>
										</Stack>
									</Grid>
									<Grid md={12} xs={12} sx={{ margin: isMobile ? "0" : "3em" }} />
								</Grid>
								<Grid md={6} xs={12}>
									<Lottie animationData={supportData} width={"100"} />
								</Grid>
							</Grid>
						</Grid>
					</div>
				</Stack>
			</main>

			<footer className="snapChild">
				<Typography level="title-lg" textColor="white">
					Fizzonline, um produto João de Barro
				</Typography>
			</footer>
		</div>
	);
}

export default Landing;
