export const fontData: string[] = [
  "Agreloy.otf",
  "Batuphat Script.otf",
  "Bhineka.otf",
  "blowbrush.otf",
  "budmo jiggler.otf",
  "budmo jigglish.otf",
  "Champignon.otf",
  "Chomsky.otf",
  "Creativo Italic.otf",
  "Creativo Regular.otf",
  "DonGraffiti.otf",
  "earwig factory rg.otf",
  "Garet-Book.otf",
  "Garet-Heavy.otf",
  "Get Show.otf",
  "Good Brush.otf",
  "heavy heap rg.otf",
  "Lobster 1.4.otf",
  "Love script.otf",
  "Marcha.otf",
  "master_of_break.otf",
  "Mechaside-Italic.otf",
  "Mechaside-Regular.otf",
  "Metal Lord.otf",
  "nasalization-rg.otf",
  "Olondon_.otf",
  "Olondona.otf",
  "pincoyablack.otf",
  "Pixel Digivolve Italic.otf",
  "Pixel Digivolve.otf",
  "Scriptina Pro.otf",
  "SnowtopCaps.otf",
  "Socafe.otf",
  "Squealer Embossed.otf",
  "Squealer.otf",
  "Stereofidelic.otf",
  "Vanilla Whale.otf",
  "zrnic rg.otf",
  "EmojiMart.ttf"
]
