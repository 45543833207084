import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/joy";
import BackgroundMenu from "./background_menu/background_menu";
import UploadMenu from "./upload_menu/upload_menu";

export default function ImageMenu(props: { handleMenuLoading: (value: [boolean, string]) => void; bgImage?: any }) {

	return (
		<Stack width="100%" spacing={2} overflow="auto" height="100%">
			<Typography level="title-md">Adicione suas imagens ao projeto</Typography>
			<UploadMenu handleMenuLoading={props.handleMenuLoading} />
			<Divider />
			<Typography level="title-md">Escolha um fundo para sua caneca</Typography>
			<Box height="100%" sx={{ overflow: "hidden", overflowY: "auto" }} padding="0em">
				<BackgroundMenu />
			</Box>
		</Stack>
	);
}
