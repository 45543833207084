import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Criação do contexto para armazenar a rota anterior
const PreviousRouteContext = createContext(null);

export function PreviousRouteProvider({ children }) {
  const location = useLocation();
  const [previousRoute, setPreviousRoute] = useState(null);

  useEffect(() => {
    setPreviousRoute(location);
  }, [location]);

  return (
    <PreviousRouteContext.Provider value={previousRoute}>
      {children}
    </PreviousRouteContext.Provider>
  );
}

// Hook para usar o contexto da rota anterior
export function usePreviousRoute() {
  return useContext(PreviousRouteContext);
}