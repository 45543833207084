import "./area.scss";

import { Helmet } from "react-helmet";

import { Box, Button, CircularProgress, Divider, Grid, Skeleton, Stack, Typography } from "@mui/joy";
import PageHeader from "../../../components/global_components/PageHeader";
import Slider from "../../../components/global_components/Slider";

import { getProjects } from "../../../services/projectService";
import { useEffect, useState } from "react";
import collectionCard from "../../../interfaces/collectionCard";
import CardPreview from "../../../components/global_components/CardPreview";
import { Link, useNavigate } from "react-router-dom";
import EmptyCollection from "../../../components/global_components/EmptyCollection";
import { useCart } from "../../../providers/cartProducts";
import OrderInterface from "../../../interfaces/orderInterface";
import { getOrders } from "../../../services/orderService";
import OrderItemPreview from "../../../components/global_components/OrderItemPreview";

export default function UserArea() {
	const nav = useNavigate();
	const product = useCart();
	const [apiData, setApiData] = useState<collectionCard[]>([]);
	const [orderData, setOrderData] = useState<OrderInterface[]>([]);
	const [orderDataErr, setOrderDataErr] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isLoadingOrder, setIsLoadingOrder] = useState<boolean>(true);

	const loadOrderData = () => {
		setIsLoadingOrder(true);
		getOrders()
			.then((x) => setOrderData(x))
			.catch((err) => {
				console.error("Erro ao carregar pedidos", err);
				setOrderDataErr(true);
			})
			.finally(() => setIsLoadingOrder(false));
	};
	useEffect(() => {
		const handleAPI = async () => {
			setIsLoading(true);
			let projects = await getProjects();
			setIsLoading(false);
			setApiData(projects.filter((x: any) => !x?.projectId.startsWith("mediaIds")));
		};
		handleAPI();
		loadOrderData();
		userRecentProjects();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function userRecentProjects() {
		if (isLoading) {
			return (
				<Box>
					{[1].map((key) => (
						<Skeleton
							key={key}
							animation="wave"
							variant="rectangular"
							width="300px"
							height="300px"
							sx={{ borderRadius: "1rem" }}
						/>
					))}
				</Box>
			);
		}
		let data = apiData
			.sort((a, b) => {
				if (b.createdAt > a.createdAt) return 1;
				if (b.createdAt < a.createdAt) return -1;
				return 0;
			})
			.slice(0, 3);

		return data.map((info: collectionCard) => {
			return (
				<Box key={info.projectId}>
					<CardPreview
						id={info.projectId}
						title={info.title}
						imgSrc={info.thumbnail}
						showFavorite={false}
						menu={true}
						firstMenuText="Editar"
						secondMenuText="Carrinho"
						handleFirstMenuItem={() => {
							nav(`/criar/${info.projectId}`);
						}}
						handleSecondMenuItem={() => {
							product.addToCart(data);
						}}
						handleCardEvent={() => {
							nav(`/criar/${info.projectId}`);
						}}
						width="300px"
						height="300px"
					/>
				</Box>
			);
		});
	}

	// function communityProjects() {
	// 	if (isLoading) {
	// 		return (
	// 			<Box sx={{ display: "flex", gap: "1em" }}>
	// 				{[1, 2, 3, 4].map((key) => (
	// 					<Skeleton
	// 						key={key}
	// 						animation="wave"
	// 						variant="rectangular"
	// 						width="300px"
	// 						height="300px"
	// 						sx={{ borderRadius: "1rem" }}
	// 					/>
	// 				))}
	// 			</Box>
	// 		);
	// 	}

	// 	return apiData.map((info) => {
	// 		return (
	// 			<div key={info.projectId}>
	// 				<CardPreview
	// 					id={info.projectId}
	// 					imgSrc={cattoMug}
	// 					menu={true}
	// 					title={info.title}
	// 					showFavorite={false}
	// 					firstMenuText="Salvar"
	// 					secondMenuText="Editar"
	// 					width="300px"
	// 					height="300px"
	// 				/>
	// 			</div>
	// 		);
	// 	});
	// }

	return (
		<Box>
			<Helmet>
				<title>Fizz | Minha Área</title>
			</Helmet>
			<Stack spacing={2} padding={{ md: "2em", xs: "0.5em 0.5em" }} maxWidth={1300} width="100vw" marginBottom="10em">
				<PageHeader title="Minha Área" />
				<Grid container spacing={2} textAlign={"center"} flexGrow={1}>
					<Grid md={6} xs={12}>
						<Box className="gridBox">
							{apiData.length === 0 && isLoading === false && <EmptyCollection />}
							{(apiData.length > 0 || isLoading === true) && (
								<>
									<Typography level="h4">Designs recentes</Typography>
									<Box sx={{ position: "relative", maxWidth: "320px" }}>
										<Slider>{userRecentProjects()}</Slider>
									</Box>
									<Divider sx={{ margin: "1em 0" }} />
									<Link to="/colecao">
										<Button color="secondary">Ver todos meus projetos</Button>
									</Link>
								</>
							)}
						</Box>
					</Grid>
					<Grid md={6} xs={12}>
						<Box className="gridBox" sx={{ alignItems: "normal" }}>
							<Typography level="h4" marginBottom="1em">
								Meus pedidos
							</Typography>
							<Grid container spacing={4} flexGrow={1} padding={"2em"} overflow="auto" justifyContent="center" maxHeight="50vh">
								<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "1em" }}>
									{isLoadingOrder && <CircularProgress />}
									{!isLoadingOrder && orderData.length === 0 && (
										<>
											<Typography level="h2">Nenhum pedido realizado</Typography>
											<Box sx={{ display: "flex", gap: "1em" }}>
												<Button color="primary" onClick={() => nav("/criar")} size="lg">
													Nova Caneca
												</Button>
												<Button color="secondary" onClick={() => nav("/colecao")} size="lg">
													Ir para coleções
												</Button>
											</Box>
										</>
									)}
								</Box>
								{orderData.length > 0 && (
									<Stack flexGrow={1} spacing={2}>
										{orderData.map((x) => (
											<OrderItemPreview key={x.orderId} order={x} />
										))}
									</Stack>
								)}
								{orderDataErr && (
									<Stack justifyContent="center" direction="row">
										<Typography level="h2">Erro ao carregar pedidos recentes</Typography>
									</Stack>
								)}
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Stack>
		</Box>
	);
}
