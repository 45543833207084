import { Card, Typography, CardContent } from "@mui/joy";
import "./CardButton.scss";

export default function CardButton(props: {
	image?: string;
	icon?: any;
	text: string;
	width?: string;
	status?: boolean;
	onChange: (status: boolean) => void;
}) {
	const handleClick = () => {
		props.onChange(!props.status);
	};
	return (
		<div onClick={handleClick}>
			<Card
				sx={{ width: props.width || "100%" }}
				variant="solid"
				className={props.status ? "cardButton active" : "cardButton"}
			>
				<CardContent orientation="vertical">
					{props.image && (
						<img
							className="img"
							src={props.image}
							loading="lazy"
							alt={props.text}
							width="100%"
							style={{ aspectRatio: "16/9", objectFit:"cover", objectPosition: "center 40%" }}
						/>
					)}
                    {
                        props.icon
                    }
					<div>
						<Typography level="title-sm">{props.text}</Typography>
					</div>
				</CardContent>
			</Card>
		</div>
	);
}
