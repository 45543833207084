import apiClient from "../plugins/apiClient";

import Customer from "../interfaces/customer";

export async function getCustomers() {
    try {
        const response = await apiClient.get(`/customer`)
        return response.data
    } catch(error) {
        console.error(error)
    }
}

export async function getCustomerByID(customerId: Customer["customerId"]) {
    try {
        const response = await apiClient.get(`/customer/${customerId}`)
        return response.data as Customer
    } catch(error) {
        console.error(error)
        throw error
    }
}

export async function newCustomer(customer: Customer) {
    try {
        const response = await apiClient.post(`/customer`, customer)
        return response.data
    } catch(error) {
        console.error(error)
    }
}

export async function editCustomer(customerId: Customer["customerId"], customer: Customer) {
    try {
        console.log(customerId)
        const response = await apiClient.put(`/customer/${customerId}`, customer)
        return response.data
    } catch(error) {
        console.error(error)
        throw error
    }
}

export async function deleteCustomer(customerId: Customer["customerId"]) {
    try {
        const response = await apiClient.delete(`/customer/${customerId}`)
        return response.data
    } catch(error) {
        console.error(error)
    }
}