import { ArrowBack } from "@mui/icons-material"
import { Box, IconButton, Tooltip } from "@mui/joy"


import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { usePreviousRoute } from "../providers/RouterTracker";
import { useCart } from "../providers/cartProducts";
import MobileDrawerMenu from "../components/login_menu/MobileDrawerMenu";

export default function MugTopLayout(props: { width: string }) {
    const [prevRoute, setPrevRoute] = useState();
    const [cartQuantity, setCartQuantity] = useState<number>(0);
    const nav = useNavigate();
    const cart = useCart();
    const previousRoute = usePreviousRoute();

    useEffect(() => {
        setPrevRoute(previousRoute?.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setCartQuantity(cart.cartList.length ?? 0)
    }, [cartQuantity, cart])

    function handleRoute() {
        if (prevRoute !== "/login" && prevRoute !== undefined) {
            nav(-1);
        } else {
            nav("/")
        }
    }

    function handleArrowTooltip() {
        const route = (prevRoute || "_").split("/")[1]
        switch (route) {
            case("colecao"):
                return "Coleção";

            case("profile"):
                return 'Perfil';
            
            case("explorar"):
                return 'Explorar';
            
            case("carrinho"):
                return "Carrinho";

            case(""):
                return "Home";
            default:
            return "Voltar"
        }
    }

    return (
        <Box sx={{ display: "flex", height: "5vh", justifyContent: "space-between", position: "fixed", right: "0", padding: "0.25em", alignItems: "center", width: props.width }}>
            <Tooltip title={handleArrowTooltip()} variant="outlined" color="primary">
                <IconButton variant='solid' color='primary' onClick={handleRoute}>
                    <ArrowBack />
                </IconButton>
            </Tooltip>
            <MobileDrawerMenu />
        </Box>
    )
}