import React, { useState } from "react";
import InputFileUpload from "../../../../global_components/InputFileUpload";
import { Stack } from "@mui/joy";
import UploadWizard from "../upload_wizard/upload_wizard";
import imageCompression from "browser-image-compression";

export default function UploadMenu(props: { handleMenuLoading: (value: [boolean, string]) => void }) {
	const [img, setImg] = useState<File | null>(null);

	const loadPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files[0];
		
		if (file.size / (1024 * 1024) >= 4) {
			const options = {
				maxSizeMB: 4,
				maxWidthOrHeight: 1920,
				useWebWorker: true,
			};
			imageCompression(file, options)
				.then((result) => {
					console.log("image compressed MB", result.size/1024/1024)
					if (result) {
						setImg(result);
					}
				})
				.catch((err) => {
					console.error("Error compressing image", err);
				});
		} else {
			setImg(file);
		}
	};

	const handleClear = () => {
		setImg(null);
	};

	return (
		<div className="media">
			<Stack className="imgUpload" spacing={2}>
				<InputFileUpload onChange={loadPhoto} />
			</Stack>
			<UploadWizard image={img} handleClose={handleClear} />
		</div>
	);
}
