import type { WithAuthenticatorProps } from "@aws-amplify/ui-react";
import { View, Text, Heading, Button, useAuthenticator, useTheme } from "@aws-amplify/ui-react";
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import { I18n } from "aws-amplify/utils";

import "./login.scss";

Amplify.configure({
	Auth: {
		Cognito: {
			userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
			userPoolId: process.env.REACT_APP_USER_POOL_ID,
			loginWith: {
				// Optional
				oauth: {
					domain: process.env.REACT_APP_USER_POOL_DOMAIN,
					scopes: ["fizzonline-public-api/user", "aws.cognito.signin.user.admin", "openid"],
					redirectSignIn: ["https://www.fizzonline.com.br/minha-area", "http://localhost:3000/minha-area"],
					redirectSignOut: ["https://www.fizzonline.com.br/", "http://localhost:3000/"],
					responseType: "code",
				},
				username: true,
			},
		},
	},
});

I18n.setLanguage("pt");
const dict = {
	pt: {
		"Sign In": "Entrar",
		"Sign in": "Entrar",
		"Sign Up": "Registrar-se",
		"Create Account": "Criar Conta",
		"Sign In with Google": "Entrar com o Google",
		"Sign Up with Google": "Entrar com o Google",
		or: "ou",
	},
};

I18n.putVocabularies(dict);

const components = {
	Header() {
		const { tokens } = useTheme();

		return <View textAlign="center" padding={tokens.space.large}></View>;
	},

	Footer() {
		const { tokens } = useTheme();

		return (
			<View textAlign="center" padding={tokens.space.large}>
				<Text color={tokens.colors.neutral[80]}>&copy; Todos os direitos reservados</Text>
			</View>
		);
	},

	SignIn: {
		Header() {
			const { tokens } = useTheme();

			return <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}></Heading>;
		},
		Footer() {
			const { toForgotPassword } = useAuthenticator();

			return (
				<View textAlign="center">
					<Button fontWeight="normal" onClick={toForgotPassword} size="small" variation="link">
						Esqueceu a senha?
					</Button>
				</View>
			);
		},
	},

	SignUp: {
		Header() {
			const { tokens } = useTheme();

			return <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}></Heading>;
		},
		Footer() {
			const { toSignIn } = useAuthenticator();

			return (
				<View textAlign="center">
					<Button fontWeight="normal" onClick={toSignIn} size="small" variation="link">
						Voltar para o login
					</Button>
				</View>
			);
		},
	},
	ConfirmSignUp: {
		Header() {
			const { tokens } = useTheme();
			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Verificação de E-mail
				</Heading>
			);
		},
		Footer() {
			return <Text></Text>;
		},
	},
	SetupTotp: {
		Header() {
			const { tokens } = useTheme();
			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Enter Information:
				</Heading>
			);
		},
		Footer() {
			return <Text></Text>;
		},
	},
	ConfirmSignIn: {
		Header() {
			const { tokens } = useTheme();
			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Enter Information:
				</Heading>
			);
		},
		Footer() {
			return <Text></Text>;
		},
	},
	ForgotPassword: {
		Header() {
			const { tokens } = useTheme();
			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Enter Information:
				</Heading>
			);
		},
		Footer() {
			return <Text></Text>;
		},
	},
	ConfirmResetPassword: {
		Header() {
			const { tokens } = useTheme();
			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Inserir informações:
				</Heading>
			);
		},
		Footer() {
			return <Text></Text>;
		},
	},
};

const formFields = {
	signIn: {
		username: {
			label: "E-mail",
			placeholder: "Insira seu e-mail",
		},
		password: {
			label: "Senha",
			placeholder: "Insira sua senha",
		},
	},
	signUp: {
		name: {
			order: 1,
			label: "Nome",
			placeholder: "Nos diga seu nome",
		},
		username: {
			order: 2,
			label: "Email",
			placeholder: "Insira seu email",
		},
		password: {
			order: 3,
			label: "Senha",
			placeholder: "Insira sua senha",
			isRequired: false,
		},
		confirm_password: {
			order: 4,
			label: "Confirmação de senha",
			placeholder: "Insira sua senha novamente",
		},
	},
	forceNewPassword: {
		password: {
			placeholder: "Insira sua senha",
		},
	},
	forgotPassword: {
		username: {
			placeholder: "Insira seu email",
		},
	},
	confirmResetPassword: {
		confirmation_code: {
			placeholder: "Insera o código de confirmação",
			label: "Código de confirmação",
			isRequired: false,
		},
		confirm_password: {
			placeholder: "Insira sua senha",
		},
	},
	setupTotp: {
		QR: {
			totpIssuer: "test issuer",
			totpUsername: "amplify_qr_test_user",
		},
		confirmation_code: {
			label: "Código de confirmação",
			placeholder: "Insira seu código de confirmação",
			isRequired: false,
		},
	},
	confirmSignIn: {
		confirmation_code: {
			label: "Código de confirmação",
			placeholder: "Digite o código de verificação",
			isRequired: false,
		},
	},
};

export default function Login({ signOut, user }: WithAuthenticatorProps) {
	return (
		<Authenticator formFields={formFields} components={components} socialProviders={["google"]}>
			{({ signOut }) => {
				window.location.href = window.location.origin;
				return <Text></Text>;
			}}
		</Authenticator>
	);
}
