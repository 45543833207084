import { useContext, Suspense } from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { ElementsContext } from "../../../../pages/create/create";
import "./emoji_menu.scss";
import { CircularProgress } from "@mui/joy";
import { v1 as uuidV1 } from "uuid";
import Element from "../../../../interfaces/element";
import ChangeSetSingleton from "../../texture_renderer/ChangeSetSingleton";

export default function EmojiMenu() {
	const { elements, setElements } = useContext(ElementsContext);
	const addEmoji = (emoji: any, event: MouseEvent) => {
		const newElement: Element = {
			id: uuidV1(),
			text: {
				value: emoji.native,
				size: 64,
				align:"center",
				verticalAlign: "bottom",
				lineHeight: 1,
				color: "#000000",
				family: "EmojiMart",
				padding: -1,
			},
			x: 300,
			y: 300,
			height: 70,
			type: "emoji",
			selected: true,
			scaleX: 2,
			scaleY: 2,
			shown: true,
			zIndex: elements.length + 1,
			rotation: 0,
		};
		setElements((els) => {
			ChangeSetSingleton.get().addHistory([newElement, ...els]);
			return [newElement, ...els];
		});
	};
	return (
		<div className="emojis">
			<Suspense fallback={<CircularProgress />}>
				<Picker
					data={data}
					onEmojiSelect={addEmoji}
					locale="pt"
					searchPosition="none"
					previewPosition="none"
					skinTonePosition="none"
					theme="light"
					perLine="7"
					dynamicWidth={true}
					width="100%"
					emojiButtonSize={42}
					emojiSize={32}
				/>
			</Suspense>
		</div>
	);
}
