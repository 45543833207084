import "./FinalStep.scss";
import { Box, Grid, Typography } from "@mui/joy";

import CardPreview from "../../global_components/CardPreview";
import StepBody from "../StepBody";
import { useCart } from "../../../providers/cartProducts";
import Slider from "../../global_components/Slider";
import { useUser } from "../../../providers/UserInfo";
import { useEffect } from "react";

export default function FinalStep(props: {
	handleStepper: (value: number | null) => void;
	handleFinalization: () => void;
}) {
	const cart = useCart();
	const user = useUser();

	function handleProducts() {
		return cart.project.map((data) => {
			return (
				<div key={data.projectId}>
					<CardPreview
						key={data.projectId}
						id={data.projectId}
						imgSrc={data.thumbnail}
						title={data.title}
						showFavorite={false}
						menu={false}
						height="300px"
						width="300px"
					/>
				</div>
			);
		});
	}

	useEffect(() => {}, [cart]);
	return (
		<StepBody handleStepper={props.handleStepper} handleFinalization={props.handleFinalization} step={2}>
			<Grid md={12} xs={12} container alignItems="center">
				<Grid md={12} xs={12}>
					<Typography level="h4">Resumo do pedido</Typography>
				</Grid>
				{cart.cartList.length > 1 && (
					<Grid md={6} xs={12} display="flex" justifyContent="center">
						<Box sx={{ position: "relative", maxWidth: "320px" }}>
							<Slider>{handleProducts()}</Slider>
						</Box>
					</Grid>
				)}
				{cart.cartList.length === 1 &&
					cart.project.map((item) => {
						console.log(item)
						return (
							<Grid md={6} xs={12}>
								<CardPreview
									key={item.projectId}
									id={item.projectId}
									imgSrc={item.thumbnail}
									title={item.title}
									menu={false}
									showFavorite={false}
									height="300px"
								/>
							</Grid>
						);
					})}
				<Grid md={6} xs={12}>
					<Box className="infoWrapper">
						<Box className="infoBox">
							<Box className="info">
								<Typography level="body-sm" sx={{ marginBottom: "0.5em" }}>
									Contato
								</Typography>
								<Typography level="title-md">{user.user.phone}</Typography>
								<Typography level="title-md">{user.user.email}</Typography>
							</Box>
						</Box>

						<Box className="infoBox">
							<Box className="info">
								<Typography level="body-sm" sx={{ marginBottom: "0.5em" }}>
									Endereço de entrega
								</Typography>
								<Typography level="title-md">{user.user.address.cep}</Typography>
								<Typography level="title-md">{user.user.address.street}</Typography>
								<Typography level="title-md">{user.user.address.number}</Typography>
							</Box>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</StepBody>
	);
}
