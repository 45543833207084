import { useEffect, useState } from "react";
import { decodeToken } from "react-jwt";
import { Button, Divider, Stack, Typography } from "@mui/joy";
import PageHeader from "../../components/global_components/PageHeader";
import { useNavigate } from "react-router-dom";
import Customer from "../../interfaces/customer";
import { getCustomers } from "../../services/customerService";
import { fetchAuthSession } from "aws-amplify/auth";
import { AuthSession } from "@aws-amplify/core/dist/esm/singleton/Auth/types";

export default function Impersonate() {
	const nav = useNavigate();
	const [userList, setUserList] = useState<Customer[]>([]);
	useEffect(() => {
		fetchAuthSession()
			.then((x: AuthSession) => {
				console.log(x.tokens.accessToken.payload);
				const token = decodeToken(x.tokens.accessToken.toString());
				console.log(token);
				if (!token["cognito:groups"].includes("admins")) {
					nav("/");
				}
				getCustomers()
					.then((x: Customer[]) => setUserList(x))
					.catch((err) => {
						console.error("Erro ao consultar usuários", err);
					});
			})
			.catch((err) => {
				console.error("Erro ao recuperar token", err);
			});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleImpersonate = (userId: string, userName: string) => {
		if (userId == null) {
			sessionStorage.removeItem("impersonatedUser");
			sessionStorage.removeItem("impersonatedUserName");
			window.location.href = window.location.origin;
			return;
		}

		sessionStorage.setItem("impersonatedUser", userId);
		sessionStorage.setItem("impersonatedUserName", userName);

		window.location.href = window.location.origin;
	};

	return (
		<Stack
			spacing={2}
			padding={{ md: "2em", xs: "0.5em 0.5em" }}
			divider={<Divider sx={{ margin: "1em 0" }} />}
			maxWidth={1300}
			width="100vw"
		>
			<PageHeader title="Impersonate"></PageHeader>
			{userList.map((x) => (
				<Stack direction="row" justifyContent="space-between">
					<Typography level="body-md">{x.name}</Typography>
					<Typography level="body-md">{x.email}</Typography>
					<Typography level="body-md">{x.customerId}</Typography>
					<Button onClick={() => handleImpersonate(x.customerId, x.name)}>Impersonate</Button>
				</Stack>
			))}
			<Button onClick={() => handleImpersonate(null, null)}>Remover Impersonação</Button>
		</Stack>
	);
}
