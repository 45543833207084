export const subcat = [
    { name: "Formas", value: "Shapes" },
    { name: "Esportes", value: "Sports" },
    { name: "Comida", value: "Food" },
    { name: "Arte", value: "Art" },
    { name: "Moda", value: "Fashion" },
    { name: "Religião", value: "Religion" },
    { name: "Viagem", value: "Travel" },
    { name: "Festividade", value: "Celebration" },
    { name: "Natureza", value: "Nature" },
    { name: "Amor", value: "Love" },
    { name: "Fantasia", value: "Fantasy" },
    { name: "Outros", value: "Miscellaneous" },
    { name: "Trabalho", value: "Work" },
]