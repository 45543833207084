import { InfoOutlined } from "@mui/icons-material";
import { ColorPaletteProp, FormControl, FormHelperText, FormLabel, Input, Typography, VariantProp } from "@mui/joy";
import { useContext, useEffect, useState } from "react";
import { ErrorsContext } from "../../providers/Errors";

export default function BaseInput(props: {
	label?: string;
	placeholder?: string;
	required?: boolean;
	minLength?: number;
	maxLength?: number;
	disabled?: boolean;
	onChange: (value: string) => void;
	value: string;
	validation?: (value) => string;
	errorMessage?: string;
	showCharLength?: boolean;
	variant?: VariantProp;
	color?: ColorPaletteProp;
}) {
	const [value, setValue] = useState<string>(props.value);
	const [errorMessage, setErrorMessage] = useState<string>(props.errorMessage);
	const useForm = useContext(ErrorsContext);

	useEffect(() => {
		props.onChange(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
		if (errorMessage) {
			checkValidations(value);
		} else {
			if (props.maxLength && value?.length > props.maxLength) {
				setErrorMessage(`Máximo ${props.maxLength} caracteres (${value.length}/${props.maxLength})`);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	useEffect(() => {
		if (errorMessage) {
			useForm.addError({
				id: props.label ?? props.placeholder,
				error: `Erro no campo "${props.label ?? props.placeholder}", ${errorMessage}`,
			});
		} else {
			useForm.removeError(props.label ?? props.placeholder);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorMessage]);

	const checkValidations = (value) => {
		if (props.validation) {
			const customValidationResponse = props.validation(value);
			setErrorMessage(props.validation(customValidationResponse));
			return !customValidationResponse;
		}
		if (props.required && (!value || value === " ")) {
			setErrorMessage("Campo obrigatório");
			return false;
		}
		if (props.minLength && (!value || value.length < props.minLength)) {
			setErrorMessage(`Mínimo ${props.minLength} caracteres (${value.length}/${props.minLength})`);
			return false;
		}
		if (props.maxLength && value?.length > props.maxLength) {
			setErrorMessage(`Máximo ${props.maxLength} caracteres (${value.length}/${props.maxLength})`);
			return false;
		}
		setErrorMessage(null);
		return true;
	};

	return (
		<FormControl>
			{props.label && (
				<FormLabel>
					{props.label} {props.required ? "*" : ""}
				</FormLabel>
			)}
			<Input
				value={props.value}
				onChange={(evt) => setValue(evt.target.value)}
				onBlur={() => checkValidations(value)}
				disabled={props.disabled}
				placeholder={props.placeholder}
				error={!!errorMessage}
				variant={props.variant ?? "outlined"}
				color={props.color ?? "primary"}
			/>
			{(errorMessage || props.showCharLength) && !props.disabled && (
				<FormHelperText>
					{errorMessage && (
						<>
							<InfoOutlined sx={{ color: "darkred" }} />
							<Typography level="body-sm" color="danger">
								{errorMessage}
							</Typography>
						</>
					)}
					{props.showCharLength && (
						<Typography level="body-sm" color="neutral" flexGrow={1} textAlign="right">
							{props.maxLength ? "(" + (value?.length ?? 0) + "/" + props.maxLength + ")" : value?.length}
						</Typography>
					)}
				</FormHelperText>
			)}
		</FormControl>
	);
}
